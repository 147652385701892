import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { truncText } from "../../../utils/text";
import { SearchedClaimsList } from "../../../redux/apiSpecifications/apiClaims";

const ClaimsListWrapper = styled.div`
  width: 100%;

  .MuiPaper-root {
    box-shadow: none;
    width: 100%;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }

  .MuiTableRow-head {
    .MuiInputBase-root {
      input {
        margin-left: 5px;
      }
    }
  }
`;

const CellWrapper = styled.div`
  cursor: pointer;
  padding: 1em 0;

  &.blocked-practice-row {
    color: #888;
  }
`;

interface IClaimsListProps {
  data: SearchedClaimsList;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
}

const ClaimsList = ({ data, onPaginationChange }: IClaimsListProps) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: data.currentPageNum! - 1,
    pageSize: data.pageLen!,
  });

  useEffect(() => {
    if (
      data.currentPageNum! - 1 !== pagination.pageIndex ||
      data.pageLen! !== pagination.pageSize
    ) {
      onPaginationChange(pagination.pageIndex + 1, pagination.pageSize);
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => {
    return [
      {
        accessorKey: "claimNumber",
        header: t("dashboard-a1-list-claim-number-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper title={row.original.claimNumber}>
            {truncText(row.original.claimNumber, 20)}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "subjectName",
        header: t("dashboard-a1-list-subject-name-column-header"),
        size: 150,
        Cell: ({ row }) => (
          <CellWrapper title={row.original.subjectName}>
            {truncText(row.original.subjectName, 25)}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "claimType",
        header: t("dashboard-a1-list-claim-type-column-header"),
        size: 100,
        Cell: ({ row }) => <CellWrapper>{row.original.claimType}</CellWrapper>,
      },
      {
        accessorKey: "claimStatus",
        header: t("dashboard-a1-list-claim-status-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>
            {t(
              `dashboard-a1-list-claim-status-code-${row.original.claimStatusCode}`
            )}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "policyNumber",
        header: t("dashboard-a1-list-policy-number-column-header"),
        size: 150,
        Cell: ({ row }) => (
          <CellWrapper title={row.original.policyNumber}>
            {truncText(row.original.policyNumber, 20)}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "plateNumberList",
        header: t("dashboard-a1-list-plate-number-list-column-header"),
        size: 150,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.plateNumberList.join(", ")}</CellWrapper>
        ),
      },
      {
        accessorKey: "openingDate",
        header: t("Data Apertura"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.openingDate}</CellWrapper>
        ),
      },
    ];
  }, [t]);

  const tableData = useMemo(() => data?.items || [], [data?.items]);

  return (
    <ClaimsListWrapper>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        enableDensityToggle={false}
        enableColumnOrdering
        muiTableBodyCellProps={{
          sx: {
            textAlign: "left",
          },
        }}
        state={{
          pagination,
        }}
        manualPagination
        rowCount={data.totalCount!}
        onPaginationChange={setPagination}
      />
    </ClaimsListWrapper>
  );
};

export default ClaimsList;
