import {
  DamagedPartRoleType,
  NewClaimStepItem,
  ResponsabilitiesType,
  VehicleCollisionPoint,
  WoundPointType,
} from "../types/claim.types";
import { SelectPair } from "../types/common.types";
import { insuranceCodes } from "./dummy-data";

export const maxDate = "30/12/9999";

export const baseUrlCrud =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:7072/api/" //"https://tpa-dev.dekra.com/api/" //
    : "/api";

export const baseUrlFesf =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:7071/api/" //"https://tpa-dev.dekra.com/fesf/api/" //
    : "/fesf";

export const baseUrlSubjects =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:7073/api/" //"https://tpa-dev.dekra.com/api/" //
      : "/api";

export const baseUrlAppraisals =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://192.168.129.132:7071/appraisals/"
    : "/appraisals";

export const baseUrlDoc =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://192.168.129.132:7071/docs/"
    : "/docs";

export const baseUrlAppraisalCom =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://192.168.129.132:7071/docs/"
    : "/api";

export const attachmentApiBaseUrl =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://192.168.129.132:7071/api"
    : baseUrlCrud;

export const baseUrlClaims =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:7074/api/" //"https://tpa-dev.dekra.com/claims/api/" //
      : "/clms";

export const PrimaId = 100;
export const DateFormat = "DD/MM/YYYY";
export const TimeFormat = "HH:mm";
export type GenderTypes = "M" | "F" | undefined;
export type BusinessTypes = "spa" | "srl" | "proprietorship" | undefined;
export type ContactUseCase = "IPR" | "PUB" | "PRI" | "ICO" | "IIN" | undefined;
export type SubjectType = "PF" | "PG";
export type DamagedPartDamageType = "" | "V" | "P" | "C";
export type DamagedPartManagementType = "" | "CG" | "NC";

export type AppraisalBranchCodeTypes =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8";
export type AppraisalPriorityTypes = 1 | 2 | 3 | 4;
export type AppraisalIsUrgent = true | false;
export type AppraisalTypeTypes = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";
export type AppraisalStatusTypes =
  | "DA"
  | "SC"
  | "PA"
  | "AD"
  | "AF"
  | "IV"
  | "CP"
  | "CQ"
  | "IP"
  | "PP"
  | "CO"
  | "CN"
  | "CK"
  | "SUS"
  | "RE"
  | "CAN"
  | "SUB";

export type AppraiserCoverageAreaTypes =
  | "country"
  | "province"
  | "region"
  | "city";

export const damagedPartRoles: DamagedPartRoleType[] = [
  "CP",
  "CN",
  "TP",
  "TN",
  "TS",
  "NP",
  "TD",
];
export const personDamageType: WoundPointType[] = [
  "front_head",
  "front_trunc",
  "front_arm_right",
  "front_arm_left",
  "front_leg_right",
  "front_leg_left",
  "rear_head",
  "rear_trunc",
  "rear_arm_right",
  "rear_arm_left",
  "rear_leg_right",
  "rear_leg_left",
];

export type ClaimStatusType = "open" | "closed-without-sequel" | "compliant";
export type AdditionalInfoDataType =
  | "subject"
  | "document"
  | "payment"
  | "contact";

export const vehicleCollisionPointType: VehicleCollisionPoint[] = [
  "front",
  "front-right",
  "right",
  "back-right",
  "back",
  "back-left",
  "left",
  "front-left",
];

export const backend = {
  envs: [
    {
      label: "localhost",
      server: "https://fakeapi.azurewebsites.net/api",
      //server: "http://127.0.0.1:5001/be-api-514bf/us-central1",
      //server: "http://localhost:7071/api",
    },
    {
      label: "dev",
      server: "https://fakeapi.azurewebsites.net/api",
      //server: "https://us-central1-be-api-514bf.cloudfunctions.net", // firebase
    },
    {
      label: "test",
      server: "https://fakeapi.azurewebsites.net/api",
      //server: "https://us-central1-be-api-514bf.cloudfunctions.net", // firebase
    },
    {
      label: "prod",
      server: "https://fakeapi.azurewebsites.net/api",
      //server: "https://us-central1-be-api-514bf.cloudfunctions.net", // firebase
    },
  ],
  paths: {
    search: "search",
    subject: "subject",
    policy: "policy",
  },
};

export const defaultLanguage = "it";
export const languages = ["it", "en", "empty"];

export const Urls = {
  home: "/",
  legacyHome: "/home",
  search: "/search",
};

const _R = "R";
const _T = "T";
const _C = "C";
const NC = "NC";

const _barems: string[][] = [
  /* 17 */ [
    _T,
    _T,
    _C,
    _T,
    _C,
    _T,
    _T,
    _T,
    _C,
    _T,
    _T,
    _C,
    _T,
    _T,
    _C,
    _C,
    _T,
    _C,
  ],
  /* 16 */ [
    _R,
    _T,
    _R,
    NC,
    _R,
    _R,
    _R,
    _R,
    _R,
    NC,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _C,
    _R,
  ],
  /* 15 */ [
    _T,
    _T,
    _T,
    _T,
    _T,
    _T,
    _T,
    _T,
    NC,
    _T,
    _T,
    _T,
    _T,
    _T,
    _C,
    _C,
    _T,
    _C,
  ],
  /* 14 */ [
    _T,
    _T,
    _T,
    _T,
    _C,
    _T,
    _T,
    _T,
    _T,
    _T,
    _C,
    _T,
    _T,
    _T,
    _C,
    _C,
    _T,
    _C,
  ],
  /* 13 */ [
    NC,
    _T,
    _R,
    _R,
    _R,
    NC,
    NC,
    _T,
    _R,
    _T,
    _R,
    _C,
    NC,
    _C,
    _R,
    _R,
    _T,
    _R,
  ],
  /* 12 */ [
    NC,
    _T,
    _R,
    _R,
    _R,
    _R,
    _T,
    _T,
    _R,
    _T,
    _R,
    _R,
    _C,
    NC,
    _R,
    _R,
    _T,
    _R,
  ],
  /* 11 */ [
    _T,
    _T,
    _R,
    NC,
    _R,
    NC,
    NC,
    _T,
    _R,
    _T,
    _R,
    _C,
    _T,
    _C,
    _R,
    _R,
    _T,
    _C,
  ],
  /* 10 */ [
    _T,
    _T,
    _R,
    NC,
    _R,
    NC,
    _T,
    _T,
    _C,
    _T,
    _C,
    _T,
    _T,
    _T,
    _C,
    _R,
    _T,
    _R,
  ],
  /*  9 */ [
    NC,
    _T,
    _R,
    NC,
    _R,
    _R,
    NC,
    _C,
    NC,
    _C,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    NC,
    _R,
  ],
  /*  8 */ [
    _T,
    _T,
    _C,
    _T,
    _C,
    _T,
    _T,
    _T,
    NC,
    NC,
    _C,
    _T,
    _T,
    _T,
    _R,
    NC,
    _T,
    _C,
  ],
  /*  7 */ [
    NC,
    _T,
    _T,
    _R,
    _R,
    _R,
    _R,
    _C,
    _R,
    _C,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _T,
    _R,
  ],
  /*  6 */ [
    _T,
    _T,
    _T,
    _R,
    _R,
    _R,
    _C,
    _T,
    _R,
    NC,
    _R,
    NC,
    _R,
    NC,
    _R,
    _R,
    _T,
    _R,
  ],
  /*  5 */ [
    _T,
    _T,
    _C,
    _C,
    _C,
    _C,
    _T,
    _T,
    _R,
    _T,
    NC,
    NC,
    _T,
    NC,
    _R,
    _R,
    _T,
    _R,
  ],
  /*  4 */ [
    _T,
    _T,
    _C,
    _C,
    _C,
    _C,
    _T,
    _T,
    _C,
    _T,
    _T,
    _T,
    _T,
    _T,
    _C,
    _R,
    _T,
    _C,
  ],
  /*  3 */ [
    _T,
    _T,
    _C,
    _C,
    _C,
    _C,
    _T,
    _T,
    _R,
    NC,
    NC,
    NC,
    _T,
    _T,
    _R,
    _R,
    NC,
    _R,
  ],
  /*  2 */ [
    _T,
    _T,
    _C,
    _C,
    _C,
    _C,
    _R,
    _R,
    _C,
    _T,
    _T,
    _T,
    _T,
    _T,
    _R,
    _R,
    _T,
    _C,
  ],
  /*  1 */ [
    _R,
    NC,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
    _R,
  ],
  /*  0 */ [
    NC,
    _T,
    _R,
    _R,
    _R,
    _R,
    _R,
    NC,
    _R,
    NC,
    _R,
    _R,
    NC,
    NC,
    _R,
    _R,
    _T,
    _R,
  ],
];

export const BaremsToResponsability: any = {
  [_R]: "R",
  [_T]: "T",
  [_C]: "C",
  [NC]: "---",
};

export const BaremsToManagement: any = {
  R: "Gestionario",
  T: "Debitore",
  C: "Concorsuale",
  "---": "---",
};

export const Barems = _barems.map((b, i) => _barems[_barems.length - i - 1]);

export const InjuryNatureNumber = 10;

export const InjuryLocationNumber = 77;

export const VehicleTypeOptions = [
  { value: "---", label: "---" },
  { value: "A", label: "AUTOVETTURA" },
  { value: "B", label: "AUTOBUS" },
  { value: "C", label: "AUTOCARRI" },
  { value: "M", label: "MOTOCICLI" },
  { value: "Q", label: "MOTOCARRI" },
  { value: "T", label: "MACCHINE OPERATRICI" },
  { value: "W", label: "CICLOMOTORI" },
  { value: "R", label: "RIMORCHI" },
  { value: "S", label: "MACCHINE AGRICOLE" },
];

export const PlateFormats = [
  { value: "---", label: "---" },
  {
    value: "T",
    label: "Targa Italia",
  },
  {
    value: "X",
    label: "Targg Prova",
  },
  {
    value: "Y",
    label: "Targg Provvisorie",
  },
  {
    value: "J",
    label: "Targa Filobus",
  },
];

export type ClaimType = "CARD" | "NO CARD";

export const VehicleTypes = ["A", "B", "C", "M", "Q", "T", "W", "R", "S"];
export const CardVehicleTypes = ["A", "B", "C", "M", "Q", "T", "W"];

export const vehicleCollisionPoints = [
  {
    code: "11",
    label: "front",
    tooltipPlacement: "top",
    path: "M 24.00,23.50 \
           C 24.00,23.50 100.40,142.96 100.40,142.96 \
             100.40,142.96 171.99,28.91 176.62,22.19 \
             171.62,18.19 171.09,17.09 162.73,12.55 \
             152.36,8.36 149.64,6.55 131.00,4.50 \
             131.00,4.50 122.18,2.55 101.45,2.36 \
             84.91,1.82 73.82,3.09 62.55,5.09 \
             50.36,7.64 47.24,8.91 44.00,10.73 \
             43.35,11.09 28.36,19.45 28.36,19.45M 102.00,354.67",
  },
  {
    code: "12",
    label: "front-right",
    tooltipPlacement: "right",
    path: "M 100.50,142.75 \
           C 100.50,142.75 100.55,142.91 100.55,142.91 \
             100.55,142.91 193.82,149.64 193.82,149.64 \
             193.82,149.64 193.45,97.82 193.27,69.27 \
             191.09,57.64 190.18,49.27 187.33,41.33 \
             186.52,38.22 182.80,31.91 180.55,28.55 \
             180.13,27.92 176.69,22.25 176.69,22.25",
  },
  {
    code: "13",
    label: "right",
    tooltipPlacement: "right",
    path: "M 99.22,353.50 	\
           C 99.22,353.50 193.33,348.00 193.33,348.00 \
             193.33,348.00 193.88,149.66 193.88,149.66 \
             193.88,149.66 100.38,142.94 100.38,142.94 \
             100.38,142.94 99.22,353.44 99.22,353.44",
  },
  {
    code: "14",
    label: "back-right",
    tooltipPlacement: "right",
    path: "M 99.27,353.51 \
           C 99.27,353.51 193.29,348.00 193.29,348.00 \
             193.29,348.00 193.10,410.82 192.98,410.82 \
             189.82,423.09 188.91,427.45 186.96,442.71 \
             185.27,448.73 185.64,448.73 182.55,456.91 \
             180.55,461.09 176.51,465.21 176.51,465.09 \
             176.51,464.96 99.58,353.93 99.58,353.93",
  },
  {
    code: "15",
    label: "back",
    tooltipPlacement: "bottom",
    path: "M 99.12,353.25 \
           C 99.12,353.25 34.38,459.62 29.88,467.12 \
             35.00,470.75 43.25,476.50 66.88,479.12 \
             66.88,479.12 137.50,478.38 137.25,478.88 \
             157.68,476.74 172.80,467.89 176.38,465.12 \
             176.98,464.66 176.50,465.00 176.50,465.00 \
             176.50,465.00 99.50,353.50 99.25,353.50",
  },
  {
    code: "16",
    label: "back-left",
    tooltipPlacement: "left",
    path: "M 8.88,348.25 \
           C 8.88,348.25 8.88,384.88 9.06,409.75 \
             11.56,419.62 14.00,429.00 16.50,445.62 \
             19.94,459.88 24.81,463.19 29.50,467.38 \
             29.50,467.38 99.00,353.50 99.00,353.50 \
             99.00,353.50 10.12,348.25 10.12,348.25",
  },
  {
    code: "17",
    label: "left",
    tooltipPlacement: "left",
    path: "M 9.00,348.11 \
           C 9.00,348.11 8.06,149.88 8.06,149.88 \
             8.06,149.88 100.44,142.89 100.44,142.89 \
             100.44,142.89 99.25,353.50 99.25,353.50 \
             99.25,353.50 9.00,348.11 9.00,348.11",
  },
  {
    code: "18",
    label: "front-left",
    tooltipPlacement: "left",
    path: "M 100.38,142.93 \
           C 100.38,142.93 8.00,149.88 8.00,149.88 \
             8.00,149.88 8.12,97.25 8.50,73.50 \
             8.17,73.50 11.75,47.25 16.92,36.67 \
             21.12,29.88 24.12,23.75 24.12,23.75 \
             24.12,23.75 100.38,143.00 100.38,143.00",
  },
];

export const NewClaimSteps: NewClaimStepItem[] = [
  {
    number: 1,
    waiting: {
      title: "---",
      description: "---",
    },
    inProgress: {
      title: "Inserisci",
      description: "Dati Obbligatori",
    },
    done: {
      title: "Dati Obbligatori",
      description: "Completato",
    },
  },
  {
    number: 2,
    waiting: {
      title: "Verifica SIC",
      description: "",
    },
    inProgress: {
      title: "Verifica SIC",
      description: "",
    },
    done: {
      title: "Verifica SIC",
      description: "Verificato",
    },
  },
  {
    number: 3,
    waiting: {
      title: "Info Addizionali",
      description: "",
    },
    inProgress: {
      title: "Inserisci",
      description: "Ulteriori Dati",
    },
    done: {
      title: "Info Addizionali",
      description: "Completato",
    },
  },
  {
    number: 4,
    waiting: {
      title: "Conferma e Invio",
      description: "",
    },
    inProgress: {
      title: "Conferma e Invio",
      description: "al Liquidatore",
    },
    done: {
      title: "---",
      description: "---",
    },
  },
];

export const Responsabilities: ResponsabilitiesType = {
  noCard: [
    { value: "---", label: "---" },
    { value: "4", label: "Passivo" },
    { value: "5", label: "Attivo" },
  ],
  card: [
    { value: "---", label: "---" },
    { value: "1", label: "Debitore" },
    { value: "2", label: "Concorsuale" },
    { value: "3", label: "Gestionario" },
  ],
};

export const PartRoleEmpty: SelectPair = { value: "", label: "---" };
export const PartRoleCP: SelectPair = {
  value: "CP",
  label: "Conducente proprietario",
};
export const PartRoleTP: SelectPair = {
  value: "TP",
  label: "Trasportato proprietario",
};
export const PartRoleNP: SelectPair = {
  value: "NP",
  label: "Proprietario non presente sul veicolo",
};
export const PartRoleCN: SelectPair = {
  value: "CN",
  label: "Conducente non proprietario",
};
export const PartRoleTN: SelectPair = {
  value: "TN",
  label: "Trasportato non proprietario",
};
export const PartRoleCPC: SelectPair = {
  value: "CPC",
  label: "Conducente proprietario controparte",
};
export const PartRoleCNC: SelectPair = {
  value: "CNC",
  label: "Conducente non proprietario controparte",
};
export const PartRoleTPC: SelectPair = {
  value: "TPC",
  label: "Trasportato proprietario controparte",
};
export const PartRoleTNC: SelectPair = {
  value: "TNC",
  label: "Trasportato non proprietario controparte",
};
export const PartRoleNPC: SelectPair = {
  value: "NPC",
  label: "NPC - Proprietario non presente sul veicolo controparte",
};
export const PartRoleTS: SelectPair = { value: "TS", label: "Terzo passante" };
export const PartRoleTD: SelectPair = {
  value: "TD",
  label: "Terzo Danneggiato",
};

export const OwnerRolesType = [
  PartRoleEmpty,
  PartRoleCP,
  PartRoleTP,
  PartRoleNP,
];

export const AllPartRoles = [
  PartRoleEmpty,
  PartRoleCP,
  PartRoleTP,
  PartRoleNP,
  PartRoleCN,
  PartRoleTN,
  PartRoleCPC,
  PartRoleCNC,
  PartRoleTPC,
  PartRoleTNC,
  PartRoleNPC,
  PartRoleTS,
  PartRoleTD,
];

export const DamageTypeEmpty: SelectPair = { value: "---", label: "---" };
export const DamageTypeThing: SelectPair = { value: "Thing", label: "Cose" };
export const DamageTypePerson: SelectPair = {
  value: "Person",
  label: "Persone",
};
export const DamageTypeVehicle: SelectPair = {
  value: "Vehicle",
  label: "Veicolo",
};
export const DamageTypeLocation: SelectPair = {
  value: "Location",
  label: "Ubicazione",
};
export const DamageTypeGeneric: SelectPair = {
  value: "Generic",
  label: "Generico",
};

export const AdditionalInfoEmpty: SelectPair = { value: "", label: "---" };
export const AdditionalInfoSubject: SelectPair = {
  value: "subject",
  label: "Soggetto",
};
export const AdditionalInfoDoc: SelectPair = {
  value: "document",
  label: "Documento",
};
export const AdditionalInfoPayment: SelectPair = {
  value: "payment",
  label: "Info Pagamento",
};
export const AdditionalInfoContact: SelectPair = {
  value: "MC",
  label: "Info Contatto",
};

export const AdditionalInfoTypes = [
  AdditionalInfoEmpty,
  AdditionalInfoSubject,
  AdditionalInfoDoc,
  AdditionalInfoPayment,
  AdditionalInfoContact,
];

export const AdditionalInfoSubjectRoleEmpty: SelectPair = {
  value: "",
  label: "---",
};
export const AdditionalInfoSubjectRoleCoachbuilder: SelectPair = {
  value: "coachbuilder",
  label: "Carrozziere",
};
export const AdditionalInfoSubjectRoleExpert: SelectPair = {
  value: "expert",
  label: "Perito",
};
export const AdditionalInfoSubjectRoleLawyer: SelectPair = {
  value: "lawyer",
  label: "Avvocato",
};
export const AdditionalInfoSubjectRoleWitness: SelectPair = {
  value: "witness",
  label: "Testimone",
};

export const AdditionalInfoSubjectRoles = [
  AdditionalInfoSubjectRoleEmpty,
  AdditionalInfoSubjectRoleCoachbuilder,
  AdditionalInfoSubjectRoleExpert,
  AdditionalInfoSubjectRoleLawyer,
  AdditionalInfoSubjectRoleWitness,
];

export const AdditionalInfoDocTypeEmpty: SelectPair = {
  value: "",
  label: "---",
};
export const AdditionalInfoDocTypeIdCard: SelectPair = {
  value: "id-card",
  label: "Carta d'identità",
};
export const AdditionalInfoDocTypePassport: SelectPair = {
  value: "PO",
  label: "Passaporto",
};
export const AdditionalInfoDocTypeExpertise: SelectPair = {
  value: "expertise",
  label: "Perizia",
};
export const AdditionalInfoDocTypePoliceReport: SelectPair = {
  value: "police-report",
  label: "Verbale polizia",
};

export const AdditionalInfoDocTypes = [
  AdditionalInfoDocTypeEmpty,
  AdditionalInfoDocTypeIdCard,
  AdditionalInfoDocTypePassport,
  AdditionalInfoDocTypeExpertise,
  AdditionalInfoDocTypePoliceReport,
];

export const UploadDocumentsAction = "localhost";

export const AdditionalInfoPaymentEmpty: SelectPair = {
  value: "",
  label: "---",
};
export const AdditionalInfoPaymentTransfer: SelectPair = {
  value: "B",
  label: "Bonifico",
};
export const AdditionalInfoPaymentCheck: SelectPair = {
  value: "A",
  label: "Assegno",
};

export const AdditionalInfoPaymentTypes = [
  AdditionalInfoPaymentEmpty,
  AdditionalInfoPaymentTransfer,
  AdditionalInfoPaymentCheck,
];

export const ForceReasonEmpty: SelectPair = { value: "", label: "---" };
export const ForceReasonSpeedLimit: SelectPair = {
  value: "1",
  label: "Mancato rispetto limiti velocità",
};
export const ForceReasonSpeedTurn: SelectPair = {
  value: "2",
  label: "Mancato rispetto norme regola strada per svolte sx dx",
};

export const ForceReasons = [
  ForceReasonEmpty,
  ForceReasonSpeedLimit,
  ForceReasonSpeedTurn,
];

export const SignatureTypeEmpty: SelectPair = { value: "", label: "---" };
export const SignatureTypeSingle: SelectPair = {
  value: "1",
  label: "Monofirma",
};
export const SignatureTypeDouble: SelectPair = {
  value: "2",
  label: "Doppia Firma",
};

export const SignatureTypes = [
  SignatureTypeEmpty,
  SignatureTypeSingle,
  SignatureTypeDouble,
];

export const LocalStorageKeys = {
  configParams: "config-params",
  activeSubject: {
    subject: "active-subject",
    attachments: "active-subject-attachments",
  },
  activeClaim: {
    stepperData: "active-claim.stepperData",
    policyData: "active-claim.policyData",
    claimData: "active-claim.claimData",
    responsability: "active-claim.responsability",
    damagedParts: "active-claim.damagedParts",
    counterpartData: "active-claim.counterpartData",
    additionalInfo: "active-claim.additionalInfo",
  },
};

export const insuranceCodesWithCodes = insuranceCodes.map((ic) => ({
  value: ic.value,
  label: `${ic.value.padStart(3, "0")} - ${ic.label}`,
}));

export const GiuridicalPersonTypeEmpty: SelectPair = {
  value: "",
  label: "---",
};
export const GiuridicalPersonTypeSrl: SelectPair = {
  value: "spa",
  label: "S.p.A.",
};
export const GiuridicalPersonTypeSpa: SelectPair = {
  value: "srl",
  label: "S.r.l",
};
export const GiuridicalPersonTypeIndividual: SelectPair = {
  value: "proprietorship",
  label: "Ditta Individuale",
};

export const GiuridicalPersonTypes = [
  GiuridicalPersonTypeEmpty,
  GiuridicalPersonTypeSrl,
  GiuridicalPersonTypeSpa,
  GiuridicalPersonTypeIndividual,
];

export const ContactTypeEmpty: SelectPair = { value: "", label: "---" };
export const ContactTypePec: SelectPair = { value: "P", label: "PEC" };
export const ContactTypeEmail: SelectPair = { value: "E", label: "Email" };
export const ContactTypePhone: SelectPair = { value: "T", label: "Telefono" };
export const ContactTypeMobile: SelectPair = { value: "C", label: "Cellulare" };

export const ContactTypes = [
  ContactTypeEmpty,
  ContactTypePec,
  ContactTypeEmail,
  ContactTypePhone,
  ContactTypeMobile,
];

export const ContactUseCaseTypeEmpty: SelectPair = { value: "", label: "---" };
export const ContactUseCaseTypeCommunications: SelectPair = {
  value: "communications",
  label: "Invio Comunicazioni",
};
export const ContactUseCaseTypeContactMode: SelectPair = {
  value: "contact-mode",
  label: "Modalità Contatto",
};
export const ContactUseCaseTypeSendInformations: SelectPair = {
  value: "send-informations",
  label: "Invio Informazioni",
};

export const ContactUseCaseTypes = [
  ContactUseCaseTypeEmpty,
  ContactUseCaseTypeCommunications,
  ContactUseCaseTypeContactMode,
  ContactUseCaseTypeSendInformations,
];

export const AddressTypeEmpty: SelectPair = { value: "", label: "---" };
export const AddressTypeDomicile: SelectPair = {
  value: "D",
  label: "Domicilio",
};
export const AddressTypeResidential: SelectPair = {
  value: "residential",
  label: "Residenza",
};
export const AddressTypeShipping: SelectPair = {
  value: "S",
  label: "Spedizione",
};
export const AddressTypeRegisteredOffice: SelectPair = {
  value: "L",
  label: "Sede Legale",
};
export const AddressTypeOperaingOffice: SelectPair = {
  value: "O",
  label: "Sede Operativa",
};

export const AddressTypes = [
  AddressTypeEmpty,
  AddressTypeDomicile,
  AddressTypeResidential,
  AddressTypeShipping,
  AddressTypeRegisteredOffice,
  AddressTypeOperaingOffice,
];

export const DocumentTypeEmpty: SelectPair = { value: "", label: "---" };
export const DocumentTypeCardId: SelectPair = {
  value: "CI",
  label: "Carta d'Identità",
};
export const DocumentTypeDrivingLicense: SelectPair = {
  value: "PG",
  label: "Patente",
};
export const DocumentTypePassport: SelectPair = {
  value: "PO",
  label: "Passaporto",
};
export const DocumentTypeFiscalCode: SelectPair = {
  value: "fiscal-code",
  label: "Codice Fiscale",
};

export const DocumentTypes = [
  DocumentTypeEmpty,
  DocumentTypeCardId,
  DocumentTypeDrivingLicense,
  DocumentTypePassport,
  DocumentTypeFiscalCode,
];

export const PaymentTypeEmpty: SelectPair = { value: "", label: "---" };
export const PaymentTypeTransfer: SelectPair = {
  value: "B",
  label: "Bonifico",
};
export const PaymentTypeCheck: SelectPair = { value: "A", label: "Assegno" };

export const PaymentsTypes = [
  PaymentTypeEmpty,
  PaymentTypeTransfer,
  PaymentTypeCheck,
];

export type MainTabsTypes =
  | "dashboard-NoRole"
  | "dashboard-D1"
  | "dashboard-D2"
  | "dashboard-E1"
  | "dashboard-E2"
  | "dashboard-E3"
  | "dashboard-E4"
  | "dashboard-F1"
  | "dashboard-NF"
  | "dashboard-G1"
  | "dashboard-G2"
  | "dashboard-H1"
  | "dashboard-A1"
  | "dashboard-B1"
  | "dashboard-C1"
  | "search-results-claims"
  | "search-results-appraisals"
  | "appraisal-details"
  //| "expertize-details"
  | "customer-details"
  | "communication-details"
  | "appraiser-details"
  | "rates-clients"
  | "user-management"
  | "my-agenda"
  | "agenda-appraiser"
  | "agenda-appraisers"
  | "configuration-panel"
  | "fees-clients"
  | "manage-report"
  | "reminders-panel"
  | "appraisal-expiration-dates-configuration"
  | "assignment-map"
  | "coverage-level-configuration"
  | "expert-score"

export type AppraisalsStatusesType =
  | undefined
  | "daily"
  | "assigned"
  | "discarded"
  | "in-verification"
  | "cancelled"
  | "in-progress";

export type ExpertizerStatusType =
  | undefined
  | "DA"
  | "SC"
  | "PA"
  | "CR"
  | "AD"
  | "AF"
  | "AFO" // extra status - appointment today
  | "IV"
  | "CP"
  | "CQ"
  | "IP"
  | "PP"
  | "CO"
  | "CN"
  | "CK"
  | "SUS"
  | "RE"
  | "CAN"
  | "SUB";

export type ExpertizerAppointmentType = undefined | "AD" | "AF";
export type ExpertizerRcaCvtType = undefined | "rca" | "cvt";

export type DashboardExperiseInitialDialogSelectionType =
  | ExpertizerStatusType
  | "notifications";
