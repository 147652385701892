import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  AppraisalQuestionnaire,
  Appraiser,
  DocProcessingAlert,
} from "../../../../../../redux/apiSpecifications/apiCrud";
import { ConcludePracticeStatuses } from "./ConcludePractice";
import { Popconfirm } from "antd";
import { ButtonConfirm } from "../../../../../Layout/Buttons";
import { SelectStyled, SwitchStyled } from "../../../../../../style/Input";
import TextArea from "antd/lib/input/TextArea";
import { RxLapTimer } from "react-icons/rx";
import moment from "moment";
import { dateTimeToNumber } from "../../../../../../utils/date";

// Styled components
const FinalizedPracticeResultWrapper = styled.div`
  display: flex;
  flex: 1;

  .ok {
    flex: 1;
    .title {
      letter-spacing: 1px;
      margin: 0 5em 1em 0;
    }

    .resume {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    .main-area {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 2em;
    }

    label {
      width: 15em;
    }
  }

  .error {
    flex: 1;
    .title {
      letter-spacing: 1px;
      margin: 0 5em 1em 0;
      color: orange;
    }
  }

  .field {
    display: flex;
    flex-direction: row;
    gap: 3em;

    .label {
      display: flex;
      flex-direction: row;
    }

    .value {
    }

    .second-value {
    }
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .timer-and-button {
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;

    .timer-wrapper {
      text-align: center;
    }

    .timer-icon {
      font-size: 2em;
      color: red;
    }
  }
`;

/**
 * @desccription
 * @interface IFinalizedPracticeResultProps
 * @property {ConcludePracticeStatuses} status
 * @property {string} practiceRefreshedStatus - status of the practice after the save questionnaire
 * @property {AppraisalQuestionnaire} questionnaire
 * @property {() => void} onModifyQuestionnaire - callback called to modify the questionnaire
 */

interface IFinalizedPracticeResultProps {
  status?: ConcludePracticeStatuses;
  practiceRefreshedStatus?: string;
  practiceStatusDate?: string;
  questionnaire?: AppraisalQuestionnaire;
  isFinalized?: boolean;
  onModifyQuestionnaire: () => void;
}

/**
 * ADZALerts component
 *
 * @component
 * @example
 * <ADZALerts alerts={[]} />
 */
const FinalizedPracticeResult: React.FC<IFinalizedPracticeResultProps> = ({
  status,
  practiceRefreshedStatus,
  practiceStatusDate,
  questionnaire,
  isFinalized,
  onModifyQuestionnaire,
}) => {
  // COMMON VARIABLES
  const { t } = useTranslation();

  // Local state
  const [popconfirmModifyQuestionnaire, setPopconfirmModifyQuestionnaire] =
    React.useState(false);

  const timeRemaining = useMemo(() => {
    if (practiceStatusDate) {
      const practiceStatusDateNumber = dateTimeToNumber(practiceStatusDate);

      const practiceStatusDateMoment = moment(
        practiceStatusDateNumber.toString(),
        "YYYYMMDDHHmmss"
      );

      const nowMoment = moment();

      const duration = moment.duration(
        practiceStatusDateMoment.add(48, "hours").diff(nowMoment)
      );

      const hours = duration.days() * 24 + duration.hours();

      const minutes = duration.minutes();

      if (minutes > 0) return `${hours}:${minutes.toString().padStart(2, "0")}`;
      else return `00:00`;
    }

    return "";
  }, [practiceStatusDate]);

  if (
    [
      "practice-finalized",
      "finalizing-practice-error",
      "practice-status-refreshed",
      "questionnaire-saved",
    ].includes(status || "") === false &&
    practiceRefreshedStatus !== "AC"
  )
    return null;

  type fieldType = {
    label: string;
    tooltip?: string;
    bValue?: boolean;
    sValue?: string;
    secondValue?: string;
  };

  const handleModifyQuestionnaire = () => {
    setPopconfirmModifyQuestionnaire(false);
    onModifyQuestionnaire();
  };

  const handleFinalizeST = () => {
    //fl
  };

  const showResume =
    [
      "practice-finalized",
      "practice-status-refreshed",
      "questionnaire-saved",
    ].includes(status || "") || practiceRefreshedStatus === "AC";

  const showError = ["finalizing-practice-error"].includes(status || "");

  // const practiceStatusDateMoment = moment(practiceStatusDate!);

  return (
    <FinalizedPracticeResultWrapper>
      {showResume && (
        <div className="ok">
          {isFinalized && (
            <div className="title">
              {practiceRefreshedStatus === "CO" &&
                t("appraisals-conclude-practice-finalized")}
              {practiceRefreshedStatus === "AC" &&
                t("appraisals-conclude-practice-questionnaire-saved")}
            </div>
          )}
          <div className="main-area">
            <div className="resume">
              {/* NEGATIVE */}
              <div className="field">
                <SwitchStyled
                  label={t(
                    "appraisals-conclude-practice-questionnaire-question-1-label"
                  )}
                  checkedChildren={t("switch-yes")}
                  unCheckedChildren={t("switch-no")}
                  onChange={() => {}}
                  checked={questionnaire?.isNegative || false}
                />
                <div>
                  {questionnaire?.negativeReasonCode === ""
                    ? ""
                    : t(
                        `appraisals-conclude-practice-questionnaire-is-negative-reason-${
                          questionnaire?.negativeReasonCode || ""
                        }`
                      )!}
                </div>
              </div>

              {/* ACCESS PROVE */}
              <div className="field">
                <SwitchStyled
                  label={t(
                    "appraisals-conclude-practice-has-access-proof-label"
                  )}
                  checkedChildren={t("switch-yes")}
                  unCheckedChildren={t("switch-no")}
                  onChange={() => {}}
                  checked={questionnaire?.accessProof || false}
                />
              </div>

              {/* AGREEMENT */}
              <SelectStyled
                label={t(
                  "appraisals-conclude-practice-questionnaire-question-2-label"
                )}
                options={[
                  {
                    value: "S",
                    label: t("switch-yes"),
                  },
                  {
                    value: "SN",
                    label: t("appraisals-conclude-practice-yes-no-file"),
                  },
                  {
                    value: "N",
                    label: t("switch-no"),
                  },
                ]}
                onChange={() => {}}
                disabled={true}
                value={questionnaire?.agreement}
              />

              {/* COMPATIBILITA' */}
              {!questionnaire?.isNegative && (
                <SelectStyled
                  label={t(
                    "appraisals-conclude-practice-questionnaire-question-3-label"
                  )}
                  options={[
                    {
                      value: "S",
                      label: t("switch-yes"),
                    },
                    {
                      value: "N",
                      label: t("switch-no"),
                    },
                    {
                      value: "V",
                      label: t("appraisals-conclude-practice-to-verify"),
                    },
                  ]}
                  onChange={() => {}}
                  disabled={true}
                  value={questionnaire?.isConsistent}
                />
              )}

              {/* ANTI ECONOMIC' */}
              <SwitchStyled
                label={t(
                  "appraisals-conclude-practice-questionnaire-question-4-label"
                )}
                checkedChildren={t("switch-yes")}
                unCheckedChildren={t("switch-no")}
                onChange={() => {}}
                checked={questionnaire?.isUneconomic || false}
              />

              {/* NOTE' */}
              <TextArea
                placeholder={
                  t("appraisals-conclude-practice-note-placeholder")!
                }
                onChange={() => {}}
                disabled={true}
                value={questionnaire?.notes || ""}
              />
            </div>

            {/* <pre>{JSON.stringify(practiceRefreshedStatus, null, 2)}</pre> */}

            {practiceRefreshedStatus === "AC" && (
              <ActionsWrapper>
                <Popconfirm
                  placement="top"
                  icon={null}
                  open={popconfirmModifyQuestionnaire}
                  title={t(
                    "appraisals-conclude-practice-modify-questionnaire-tooltip"
                  )}
                  onConfirm={() => handleModifyQuestionnaire()}
                  onCancel={() => setPopconfirmModifyQuestionnaire(false)}
                  okText={t("switch-yes")}
                  cancelText={t("switch-no")}
                >
                  <div className="timer-and-button">
                    <div className="timer-wrapper">
                      <div className="timer-icon">
                        <RxLapTimer />
                      </div>
                      {t(
                        "appraisals-conclude-practice-questionnaire-saved-timer-1"
                      )}
                      <br />
                      {/* {"hh:mm "} */}
                      <span>{timeRemaining} </span>
                      {t(
                        "appraisals-conclude-practice-questionnaire-saved-timer-2"
                      )}
                    </div>
                    <ButtonConfirm
                      onClick={() => setPopconfirmModifyQuestionnaire(true)}
                    >
                      {
                        t(
                          "appraisals-conclude-practice-modify-questionnaire-button"
                        )!
                      }
                    </ButtonConfirm>
                  </div>
                </Popconfirm>
              </ActionsWrapper>
            )}
          </div>
        </div>
      )}

      {showError && (
        <div className="error">
          <div className="title">
            {t("appraisals-conclude-practice-finalizing-practice-error")!}
          </div>
        </div>
      )}
    </FinalizedPracticeResultWrapper>
  );
};

export default FinalizedPracticeResult;
