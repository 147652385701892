import React, { Ref, RefObject, useRef, useState } from "react";
import {
    Input,
    Form,
    Select as SelectAnt,
    InputNumber,
    DatePicker,
    TimePicker,
    Segmented,
    Switch,
    Tag,
    InputRef,
} from "antd";
import styled from "styled-components";
import { Hidden } from "./containers";
import moment from "moment";
import { SegmentedValue } from "antd/lib/segmented";
import dekra from "./dekra";
import { FormLabelAlign } from "antd/lib/form/interface";
import { SelectPair, ValidationStatuses } from "../types/common.types";
import { DateFormat } from "../config/const";
import { useTranslation } from "react-i18next";
import { PropaneSharp } from "@mui/icons-material";
import { SwitchSize } from "antd/lib/switch";
import TextArea from "antd/lib/input/TextArea";

export const FormItemStyled = styled(Form.Item)`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;

    .label-wrapper {
        display: flex;
        align-items: center; 
        justify-content: flex-start;

        label {
            text-transform: uppercase;
            font-size: 0.9em;
            letter-spacing: 1px;
        }
    }

    .ant-input-number {
        width: 100%;

        input {
            text-align: right;
            padding-right: 2em;
        }
    }

    .ant-picker {
        width: 10em;
    }

    .ant-switch-checked {
        background-color: ${dekra.primaryColor};
    }

    .ant-select-disabled {
        .ant-select-selection-item {
            color: #333;
        }
    }

    .ant-picker-input > input[disabled] {
        color: black;
    }

    .ant-input-number input {
        color: black;
    }

    .ant-switch-disabled {
        cursor: not-allowed;
        opacity: 1;
    }

    &.number {
        .ant-input {
            text-align: right;
        }
    }
`;


export const GridRowStyled = styled.div`
    .grid-container {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: auto auto;
        width: 100%;

        .grid-item {
            width: 100%;
            text-align: center;
        }

        .ant-form-item-row {
            flex-direction: row;
        }
    }

    .marginBottomBadge {
        margin-bottom: 3px;
    }
`;

export interface LabelProps {
    classNameItem?: string;
    styleItem?: any;
    styleInput?: any;
    label?: string | null;
    tooltip?: string | null;
    dataTestId?: string | undefined | null;
}

export const LabelStyled = (props: LabelProps) => {
    return (
        <FormItemStyled
            className={props.classNameItem}
            style={props.styleItem}
            label={props.label}
            tooltip={props.tooltip}
            data-testid={props.dataTestId}
        ></FormItemStyled>
    );
};

export interface InputProps {
    name?: string;
    type?: string;
    classNameItem?: string;
    classNameInput?: string;
    styleItem?: any;
    styleInput?: any;
    value?: any;
    defaultValue?: any;
    label?: string | null;
    labelAlign?: FormLabelAlign;
    tooltip?: string | null;
    rules?: any;
    placeholder?: string | null;
    readOnly?: boolean;
    validateStatus?: ValidationStatuses;
    help?: string | undefined | null;
    required?: boolean | undefined;
    dataTestId?: string | undefined;
}

export interface InputTextProps extends InputProps {
    maxLength?: number;
    mimLength?: number;
    onChange?: (text: string) => void;
    onPressEnter?: () => void;
    onClick?: (e: any) => void;
    onDoubleClick?: () => void;
    disabled?: boolean;
}

export const InputTextStyled = (props: InputTextProps) => {
    let label = props.label;

    if (props.required === true) label += " *";

    return (
        <FormItemStyled
            className={`${props.classNameItem} ${
                props.type === "number" ? "number" : ""
            }`}
            name={props.name}
            style={props.styleItem}
            label={label}
            tooltip={props.tooltip}
            validateStatus={props.validateStatus}
            help={props.help}
            labelAlign={props.labelAlign}
        >
            <Input
                type={props.type || "text"}
                disabled={props.disabled}
                className={props.classNameInput}
                style={{
                    ...props.styleInput,
                    color: props.disabled ? "#333" : "inherit",
                }}
                min={props.mimLength}
                max={props.maxLength}
                onChange={(e) =>
                    props.onChange ? props.onChange(e.target.value) : null
                }
                value={props.value}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder || undefined}
                readOnly={props.readOnly}
                onPressEnter={props.onPressEnter}
                onClick={props.onClick}
                onDoubleClick={props.onDoubleClick}
                required={props.required}
                data-testid={props.dataTestId}
                maxLength={props.maxLength}
            />

            <Hidden>{props.value}</Hidden>
        </FormItemStyled>
    );
};

export interface TextAreaProps extends InputTextProps {
  rows?: number
}

export const TextAreaStyled = (props: TextAreaProps) => {
    let label = props.label;

    if (props.required === true) label += " *";

    return (
        <FormItemStyled
            className={`${props.classNameItem} ${
                props.type === "number" ? "number" : ""
            }`}
            name={props.name}
            style={props.styleItem}
            label={label}
            tooltip={props.tooltip}
            validateStatus={props.validateStatus}
            help={props.help}
            labelAlign={props.labelAlign}
        >
            <TextArea
                rows={props.rows || 5}
                value={props.value}
                onChange={(e) =>
                    props.onChange ? props.onChange(e.target.value) : null
                }
                placeholder={props.placeholder || undefined}
            />
        </FormItemStyled>
    );
};

interface InputNumberProps extends InputProps {
    max?: number;
    min?: number;
    onChange: (val: number) => void;
    onClick?: (e: any) => void;
    onDoubleClick?: () => void;
    disabled?: boolean | undefined;
    controls?: any;
}

export const InputNumberStyled = (props: InputNumberProps) => {
    return (
        <FormItemStyled
            className={props.classNameItem}
            name={props.name}
            style={props.styleItem}
            label={props.label}
            tooltip={props.tooltip}
            rules={props.rules}
            validateStatus={props.validateStatus}
            help={props.help}
            labelAlign={props.labelAlign}
        >
            <InputNumber
                type="number"
                controls={props.controls}
                className={props.classNameInput}
                style={props.styleInput}
                min={props.min}
                max={props.max}
                onChange={(val) => props.onChange(val)}
                onClick={props.onClick}
                onDoubleClick={props.onDoubleClick}
                value={props.value}
                defaultValue={props.defaultValue}
                readOnly={props.readOnly}
                disabled={props.disabled}
                data-testid={props.dataTestId}
            />
            <Hidden>{props.value}</Hidden>
        </FormItemStyled>
    );
};

export type SelectMode = "multiple" | "tags" | undefined;

export interface SelectProps extends InputProps {
    showSearch?: boolean;
    filterOption?: (input: any, option: SelectPair | undefined) => boolean;
    options: SelectPair[];
    onChange: (val: any) => void;
    open?: boolean;
    disabled?: boolean;
    dropdownRender?: (menu: any) => any;
    mode?: SelectMode;
    allowClear?: boolean;
}

export const SelectStyled = (props: SelectProps) => {
    return (
        <FormItemStyled
            className={props.classNameItem}
            name={props.name}
            style={props.styleItem}
            label={props.label}
            tooltip={props.tooltip}
            labelAlign={props.labelAlign}
        >
            {props.dropdownRender && (
                <SelectAnt
                    disabled={props.disabled}
                    open={props.open}
                    showSearch={props.showSearch}
                    className={props.classNameInput}
                    style={props.styleInput}
                    onChange={(val) => props.onChange(val)}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    options={props.options}
                    filterOption={props.filterOption}
                    data-testid={props.dataTestId}
                    dropdownRender={props.dropdownRender}
                    mode={props.mode}
                    allowClear={props.allowClear}
                />
            )}

            {!props.dropdownRender && (
                <SelectAnt
                    disabled={props.disabled}
                    open={props.open}
                    showSearch={props.showSearch}
                    className={props.classNameInput}
                    style={props.styleInput}
                    onChange={(val) => props.onChange(val)}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    options={props.options}
                    filterOption={props.filterOption}
                    data-testid={props.dataTestId}
                    mode={props.mode}
                    allowClear={props.allowClear}
                />
            )}
            <Hidden>{props.value}</Hidden>
        </FormItemStyled>
    );
};

export const SelectGenderStyled = (props: SelectProps) => {
    return (
        <FormItemStyled
            className={props.classNameItem}
            name={props.name}
            style={props.styleItem}
            label={props.label}
            tooltip={props.tooltip}
            labelAlign={props.labelAlign}
        >
            <SelectAnt
                showSearch={props.showSearch}
                className={props.classNameInput}
                style={props.styleInput}
                onChange={(val) => props.onChange(val)}
                value={props.value}
                defaultValue={props.defaultValue}
                options={props.options}
                filterOption={props.filterOption}
                data-testid={props.dataTestId}
            />
            <Hidden>{props.value}</Hidden>
        </FormItemStyled>
    );
};

interface DatePickerProps extends InputProps {
    onChange: (val: any) => void;
    disabledDate?: (currentDate: moment.Moment) => boolean;
    disabled?: boolean | undefined;
    required?: boolean;
}

const today = moment();
const disablePreviousDate = (currentDate: moment.Moment) => {
    return currentDate.isAfter(today);
};

/**
 * Render an Antd DatePicker leaving you select only a date earlier than or equal to today
 */
export const BirthDateStyled = (props: DatePickerProps) => {
    const value = props.value
        ? moment(props.value as string, DateFormat)
        : null;
    return (
        <FormItemStyled
            className={props.classNameItem}
            name={props.name}
            style={props.styleItem}
            label={props.label}
            tooltip={props.tooltip}
            labelAlign={props.labelAlign}
            validateStatus={props.validateStatus}
            help={props.help}
        >
            <DatePicker
                disabledDate={disablePreviousDate}
                placeholder={props.placeholder || undefined}
                onChange={(val) => props.onChange(val?.format(DateFormat))}
                value={value}
                format={DateFormat}
                data-testid={props.dataTestId}
            />
            <Hidden>{props.value?.toString()}</Hidden>
        </FormItemStyled>
    );
};

export const DatePickerStyled = (props: DatePickerProps) => {
    const value = props.value
        ? moment(props.value as string, DateFormat)
        : null;
    const { t } = useTranslation();
    const label = props.label + (props.required ? " *" : "");
    const className = props.classNameItem
        ? props.classNameItem
        : "" + (props.required ? " required" : "");

    return (
        <FormItemStyled
            className={className}
            name={props.name}
            style={props.styleItem}
            label={label}
            tooltip={props.tooltip}
            labelAlign={props.labelAlign}
            validateStatus={props.validateStatus}
            help={props.help}
        >
            <DatePicker
                onChange={(val) => props.onChange(val?.format(DateFormat))}
                value={value}
                format={DateFormat}
                data-testid={props.dataTestId}
                disabledDate={props.disabledDate}
                inputReadOnly={props.readOnly}
                disabled={props.disabled}
                placeholder={
                    props.placeholder || t("date-select-placeholder") || ""
                }
            />
            <Hidden>{props.value?.toString()}</Hidden>
        </FormItemStyled>
    );
};

interface TimePickerProps extends InputProps {
    onChange: (val: any) => void;
    format?: string;
}

export const TimePickerStyled = (props: TimePickerProps) => {
    const { t } = useTranslation();

    return (
        <FormItemStyled
            className={props.classNameItem}
            name={props.name}
            style={props.styleItem}
            label={props.label}
            tooltip={props.tooltip}
            labelAlign={props.labelAlign}
            validateStatus={props.validateStatus}
            help={props.help}
        >
            <TimePicker
                placeholder={
                    props.placeholder || t("time-select-placeholder") || ""
                }
                onChange={(val) => props.onChange(val?.toString())}
                value={moment(props.value as string, props.format)}
                format={props.format}
                data-testid={props.dataTestId}
            />
            <Hidden>{props.value?.toString()}</Hidden>
        </FormItemStyled>
    );
};

export interface SegmentedProps extends InputProps {
    onChange: (val: SegmentedValue) => void;
    options: string[];
}

export const SegmentedStyled = (props: SegmentedProps) => {
    return (
        <FormItemStyled
            className={props.classNameItem}
            style={props.styleItem}
            label={props.label}
            tooltip={props.tooltip}
            labelAlign={props.labelAlign}
        >
            <Segmented
                onChange={props.onChange}
                value={props.value}
                options={props.options}
                data-testid={props.dataTestId}
                disabled={props.readOnly}
            />
            <Hidden>{props.value?.toString()}</Hidden>
        </FormItemStyled>
    );
};

interface InputAddressProps extends InputTextProps {
    onEdit: (data: any) => void;
    value: string;
}

export const InputAddress = (props: InputAddressProps) => {
    return (
        <FormItemStyled
            className={props.classNameItem}
            name={props.name}
            style={props.styleItem}
            label={props.label}
            tooltip={props.tooltip}
            labelAlign={props.labelAlign}
            validateStatus={props.validateStatus}
            help={props.help}
        >
            <Input
                value={props.value}
                readOnly={true}
                style={Object.assign({}, props.styleInput, {
                    cursor: "pointer",
                })}
                data-testid={props.dataTestId}
            />

            <Hidden>{props.value}</Hidden>
        </FormItemStyled>
    );
};

interface SwitchProps {
    classNameItem?: string;
    classNameInput?: string;
    styleItem?: any;
    styleInput?: any;
    label?: string | null;
    labelAlign?: FormLabelAlign;
    tooltip?: string | null;
    rules?: any;
    placeholder?: string;
    readOnly?: boolean;
    checked?: boolean;
    unCheckedChildren: string;
    checkedChildren: string;
    onChange?: (checked: boolean) => void;
    disabled?: boolean | undefined;
    size?: SwitchSize;
    dataTestId?: string | undefined;
}

export const SwitchStyled = (props: SwitchProps) => (
    <FormItemStyled
        className={props.classNameItem}
        style={props.styleItem}
        label={props.label}
        tooltip={props.tooltip}
        labelAlign={props.labelAlign}
    >
        <Switch
            className={props.classNameInput}
            unCheckedChildren={"No"}
            checkedChildren={"Si"}
            onChange={(val) => (props.onChange ? props.onChange(val) : null)}
            checked={props.checked}
            disabled={props.disabled}
            size={props.size}
            data-testid={props.dataTestId}
        />
        <Hidden>{props.checked}</Hidden>
    </FormItemStyled>
);
