import React, { useMemo, useState } from "react";
import {
  ConcludePracticeStatuses,
  UpdateQuestionnaireDataType,
} from "./ConcludePractice";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ButtonConfirm } from "../../../../../Layout/Buttons";
import SolveMissingAgreement from "./SolveMissingAgreement";
import Questionnaire from "./Questionnaire";
import { IconLoading } from "../../../../../../config/icons";
import ADZPreview, { ADZPreviewStatuses } from "./ADZPreview";
import ADZALerts from "./ADZAlerts";
import {
  AppraisalDocProcessingOutput,
  AppraisalQuestionnaire,
  AttachmentFile,
  DocumentFiles,
  PracticeAttachment,
  PracticeDocument,
  RejectionRequest,
} from "../../../../../../redux/apiSpecifications/apiCrud";
import { Popconfirm, Tooltip } from "antd";
import IsNegativeSelection from "./IsNegativeSelection";
import SavedQuestionnaireResult from "./SavedQuestionnaireResult";
import FinalizedPracticeResult from "./FinalizedPracticeResult";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import RejectPracticeModal from "./RejectPracticeModal/RejectPracticeModal";

// Styled components
const ConcludePracticePresentationalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2em 0;
  position: relative;

  .row {
    display: flex;
    flex-direction: row;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 2em;
    }

    .right {
      flex: 1;
    }
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2em;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-content {
    width: 10em;
    height: 10em;
  }
`;

/**
 * @desccription
 * @interface IConcludePracticePresentationaProps
 * @property {number} idPractice - id of the practice
 * @property {boolean | undefined} isPrima - if the client is "Prima"
 * @property {boolean | undefined} isNegative - if the practice is marked as negative
 * @property {string | undefined} isNegativeReason - reason of the negative practice
 * @property {number | undefined} isAgreement - if the practice has an agreement
 * @property {number | undefined} isAntieconomic - if the practice is antieconomic
 * @property {ConcludePracticeStatuses} status - status of the procedure "Conclude Practice"
 * @property {ADZPreviewStatuses} adzStatus - status of the ADZ
 * @property {AppraisalDocProcessingOutput} adzResult - result of the ADZ
 * @property {string} adzErrorMessage - error message of the ADZ
 * @property {number} clientId - id of the client
 * @property {string} practiceRefreshedStatus - the practice status after the save questionnaire
 * @property {() => void} onRestart - reset the procedure
 * @property {(numberOfWarnings: number) => void} onLoadedValidADZ - callback called when the ADZ is loaded and valid
 * @property {() => void} onLoadedADZWithoutAgreement - callback called when the ADZ is loaded but agreement is not found
 * @property {() => void} onStartQuestionnaire - callback called when the user clicks on "Questionario" button
 * @property {() => void} onResolveMissingAgreement - callback called when the user clicks on "Risolvi warning concordato" button
 * @property {(d: PracticeDocument)=> void} onAddProofOfAccess - callback called when the user adds an attachment
 * @property {(any) => void} onSaveQuestionnaire - callback called when the user want to save the questionnaire result
 * @property {(boolean | undefined) => void} onChangeIsNegative - change isNegative value
 * @property {() => void} onConcludePractice - callback called when the user clicks on "Concludi pratica" button
 * @property {(string ) => void} onChangeIsNegativeReason - change isNegativeReason value
 * @property {() => void} onReset - reset the procedure
 * @property {(d: DocumentFiles) => void} onAddConservativeAgreement - callback called when the user clicks on "Aggiungi concordato conservativo" button
 * @property {() => void} onRemoveConservativeAgreement - callback called when the user clicks on "Rimuovi concordato conservativo" button
 * @property {() => void} onModifyQuestionnaire - callback called when the user clicks on "Modifica questionario" button
 */

interface IConcludePracticePresentationaProps {
  idPractice: number | undefined;
  isPrima: boolean | undefined;
  isFinalized?: boolean;
  status: ConcludePracticeStatuses;
  practiceStatus: string;
  practiceStatusDate?: string;
  adzDocument: PracticeAttachment | undefined;
  adzStatus: ADZPreviewStatuses;
  adzResult?: AppraisalDocProcessingOutput;
  questionnaireResult: AppraisalQuestionnaire;
  practiceRefreshedStatus?: string;
  adzErrorMessage?: string;
  clientId?: number;
  onLoadADZ: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRestart: () => void;
  onStartQuestionnaire: () => void;
  onResolveMissingAgreement: () => void;
  onUpdateQuestionnaire: (val: UpdateQuestionnaireDataType) => void;
  onAddProofOfAccess: (d: PracticeDocument) => void;
  onSaveQuestionnaire: (q: AppraisalQuestionnaire) => void;
  onConcludePractice: (q: AppraisalQuestionnaire | null) => void;
  onChangeIsNegative: (value: boolean | undefined) => void;
  onChangeIsNegativeReason: (reason: string) => void;
  onReset: () => void;
  onAddConservativeAgreement: (d: DocumentFiles) => void;
  onRemoveConservativeAgreement: () => void;
  onModifyQuestionnaire: () => void;
  onRejectPractice: (rejectRequest: RejectionRequest) => void;
  onResetLoadADZ: () => void;
}

/**
 * ConcludePractice component
 *
 * @component
 * @example
 * <ConcludePractice />
 */
const ConcludePracticePresentational: React.FC<
  IConcludePracticePresentationaProps
> = ({
  status,
  practiceStatus,
  practiceStatusDate,
  adzStatus,
  adzDocument,
  adzResult,
  adzErrorMessage,
  idPractice,
  practiceRefreshedStatus,
  isPrima,
  isFinalized,
  questionnaireResult,
  onLoadADZ,
  onStartQuestionnaire,
  onResolveMissingAgreement,
  onUpdateQuestionnaire,
  onAddProofOfAccess,
  onSaveQuestionnaire,
  onConcludePractice,
  onChangeIsNegative,
  onChangeIsNegativeReason,
  onReset,
  onAddConservativeAgreement,
  onRemoveConservativeAgreement,
  onModifyQuestionnaire,
  onRejectPractice,
  onResetLoadADZ,
}) => {
  // Common variables
  const { t } = useTranslation();

  const { activeRole } = useSelector((state: RootState) => state.user);

  // Local state
  const refAddADZ = React.useRef<HTMLInputElement>(null);
  const [popconfirmFinalizeST, setPopconfirmFinalizeST] =
    useState<boolean>(false);

  const [showRejectPracticeModal, setShowRejectPracticeModal] =
    useState<boolean>(false);

  // HANDLES

  /**
   * Trigger the selection of a file
   */
  const onStartLoadAdz = () => {
    if (practiceRefreshedStatus == "RE") onResetLoadADZ();
    else refAddADZ.current?.click();
  };

  const isLoading = [
    "loading-adz",
    "loading-document",
    "saving-questionnaire",
    "finalizing-practice",
    "loading-questionnaire",
    "rejecting-practice",
  ].includes(status);

  const showIsNegativeSelection =
    ["CO", "RE", "CI", "CR", "CF", "AC"].includes(
      practiceRefreshedStatus || status
    ) == false && ["select-is-negative"].includes(status);

  const showADZPreview = (() => {
    if (["CO", "CI", "CR", "CF"].includes(practiceRefreshedStatus || ""))
      return false;

    if (["finalizing-practice-error"].includes(status || "")) return true;

    return false;
  })();

  const showLoadADZ = (() => {
    // initial state before loading ADZ
    if (["CO", "CI", "CR", "CF"].includes(practiceRefreshedStatus || ""))
      return false;
    if (practiceRefreshedStatus == "RE") return true;

    if (status === "select-is-negative") {
      if (questionnaireResult.isNegative === undefined) return false; // the reason is not set
      if (!questionnaireResult.isNegative) return true; // is not negative

      const hasReason =
        (questionnaireResult.negativeReasonCode?.length || 0) > 0; // a reason is set
      return hasReason;
    } else {
      return [
        "load-adz",
        "loading-adz",
        "adz-valid",
        "adz-invalid",
        "adz-valid-with-warnigns",
        "adz-without-agreement",
      ].includes(status);
    }
  })();

  const showAlerts =
    practiceStatus != "CO" &&
    [
      "adz-valid",
      "adz-invalid",
      "adz-without-agreement",
      "adz-valid-with-warnigns",
    ].includes(status);

  const showMissingAgreement =
    practiceStatus != "CO" && ["solve-missing-agreement"].includes(status);
  const showQuestionnaire =
    //["CO", "CI", "CR", "CF"].includes(practiceRefreshedStatus || "") ||
    ["questionnare", "loading-document"].includes(status);

  const showConcludePractice =
    ["E1", "E4"].includes(activeRole!) && ["IV"].includes(practiceStatus);

  const renderLoadADZ = () => {
    if (!showLoadADZ) return null;

    const withConfirmation = (
      <Popconfirm
        placement="top"
        icon={null}
        title={t("appraisals-conclude-practice-reload-adz")}
        onConfirm={onStartLoadAdz}
        onCancel={onReset}
        okText={t("appraisals-conclude-practice-reload-adz-only-file")}
        cancelText={t(
          "appraisals-conclude-practice-reload-adz-file-and-negative"
        )}
      >
        <ButtonConfirm onClick={() => {}}>
          {t("appraisals-conclude-practice-button-load-adz")!}
        </ButtonConfirm>
      </Popconfirm>
    );

    const withoutConfirmation = (
      <ButtonConfirm onClick={onStartLoadAdz}>
        {t("appraisals-conclude-practice-button-load-adz")!}
      </ButtonConfirm>
    );

    return (
      <>
        {status === "select-is-negative"
          ? withoutConfirmation
          : withConfirmation}

        <input
          ref={refAddADZ}
          type="file"
          accept=".ADZ"
          style={{ display: "none" }}
          onChange={onLoadADZ}
        />
      </>
    );
  };

  const showRejectButton: boolean = useMemo(() => {
    return ["E1", "E4"].includes(activeRole!) && practiceStatus === "IV";
  }, [activeRole, practiceStatus]);

  const handleOnRejectPractice = () => setShowRejectPracticeModal(true);

  const handleOnCancelRejectPractice = () => setShowRejectPracticeModal(false);

  const handleOnConfirmRejectPractice = (rejectRequest: RejectionRequest) => {
    setShowRejectPracticeModal(false);

    onRejectPractice(rejectRequest);
  };

  const handleConcludePracticeST = () => {
    setPopconfirmFinalizeST(false);
    onConcludePractice(null);
  };

  return (
    <>
      <ConcludePracticePresentationalWrapper>
        {isLoading && (
          <LoadingWrapper>
            <div className="loading-content"> {IconLoading}</div>
          </LoadingWrapper>
        )}

        {showIsNegativeSelection && (
          <div className="row">
            <IsNegativeSelection
              value={questionnaireResult.isNegative}
              onChange={onChangeIsNegative}
              isNegativeReason={questionnaireResult.negativeReasonCode}
              onChangeReason={onChangeIsNegativeReason}
            />
          </div>
        )}

        <div className="row">
          <div className="left">
            {showADZPreview && (
              <ADZPreview status={adzStatus} adzDocument={adzDocument} />
            )}
          </div>
          <div className="right">
            {showAlerts && (
              <ADZALerts alerts={adzResult?.alerts} message={adzErrorMessage} />
            )}

            {showMissingAgreement && (
              <SolveMissingAgreement
                onUpdateQuestionnaire={onUpdateQuestionnaire}
              />
            )}

            {showQuestionnaire && (
              <Questionnaire
                questionnaire={questionnaireResult}
                isPrima={isPrima}
                onReset={onReset}
                onConfirmQuestionnaire={onSaveQuestionnaire}
                onConcludePractice={onConcludePractice}
                onAddConservativeAgreement={onAddConservativeAgreement}
                onRemoveConservativeAgreement={onRemoveConservativeAgreement}
              />
            )}

            <SavedQuestionnaireResult status={status} />

            <FinalizedPracticeResult
              status={
                ["CO", "RE", "CI", "CR", "CF"].includes(
                  practiceRefreshedStatus || ""
                )
                  ? "practice-finalized"
                  : status
              }
              isFinalized={isFinalized}
              practiceRefreshedStatus={practiceRefreshedStatus}
              practiceStatusDate={practiceStatusDate}
              questionnaire={questionnaireResult}
              onModifyQuestionnaire={onModifyQuestionnaire}
            />
          </div>
        </div>

        <ActionsWrapper>
          {renderLoadADZ()}
          {showConcludePractice && (
            <Popconfirm
              placement="top"
              icon={null}
              open={popconfirmFinalizeST}
              title={t(
                "appraisals-conclude-practice-save-conclude-practice-confirm-st"
              )}
              onConfirm={handleConcludePracticeST}
              onCancel={() => setPopconfirmFinalizeST(false)}
              okText={t("switch-yes")}
              cancelText={t("switch-no")}
            >
              <ButtonConfirm onClick={() => setPopconfirmFinalizeST(true)}>
                {t("appraisals-conclude-practice-button-conclude-practice")!}
              </ButtonConfirm>
            </Popconfirm>
          )}

          {showRejectButton && (
            <ButtonConfirm onClick={handleOnRejectPractice}>
              {t("appraisals-conclude-practice-button-reject")!}
            </ButtonConfirm>
          )}

          {["adz-valid", "adz-valid-with-warnigns"].includes(status) && (
            <ButtonConfirm onClick={onStartQuestionnaire}>
              {t("appraisals-conclude-practice-button-questionnaire")!}
            </ButtonConfirm>
          )}

          {["adz-without-agreement"].includes(status) && (
            <ButtonConfirm onClick={onResolveMissingAgreement}>
              {
                t(
                  "appraisals-conclude-practice-button-solve-missing-agreement"
                )!
              }
            </ButtonConfirm>
          )}
        </ActionsWrapper>
      </ConcludePracticePresentationalWrapper>

      {showRejectPracticeModal && (
        <RejectPracticeModal
          onCancel={handleOnCancelRejectPractice}
          onConfirm={handleOnConfirmRejectPractice}
        />
      )}
    </>
  );
};

export default ConcludePracticePresentational;
