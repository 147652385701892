import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FormRow } from "../../../../../../style/form";
import { ButtonConfirm } from "../../../../../Layout/Buttons";
import {
  DatePickerStyled,
  InputTextStyled,
  SelectStyled,
} from "../../../../../../style/Input";
import { SelectPair } from "../../../../../../types/common.types";
import { WidgetWrapper } from "../../../../../../style/DashbordWidgetWrappers";
import { RowSpacer } from "../../../../../../style/containers";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";

const CreateClaimWrapper = styled(WidgetWrapper)`
  width: 100%;
  height: 100%;
  flex-direction: column;

  .widget-title {
    padding: 0.5em 2em;
    display: flex;
    color: #333;
    background-color: #ededed;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .body {
    padding: 2em;

    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .buttons-wrapper {
    width: 100%;
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface ICreateClaimProps {
  gridArea?: string;
}

const CreateClaim = (props: ICreateClaimProps) => {
  const { t } = useTranslation();

  const { authorization, userDetails } = useSelector(
    (state: RootState) => state.user
);
const tenantsOptions: SelectPair[] = [];

if (userDetails?.tenants) {
  for (let tenant of userDetails?.tenants) {
    tenantsOptions.push({
      value: tenant.id,
      label: tenant.companyName!
    })
  }
}

  const handleFilterSearchLabel = (input: string, option: any) => {
    const text = input.toLowerCase();
    return option.label.toLowerCase().includes(text) || false;
  };
  return (
    <CreateClaimWrapper>
      <div className="widget-title">{t("create-claim-form-title")}</div>

      <div className="body">
        <FormRow style={{ alignItems: "flex-end" }}>
          <SelectStyled
            label={t("create-claim-form-tenant-selection")}
            classNameInput="input-province"
            value={" "}
            options={tenantsOptions}
            onChange={() => {}}
            showSearch
            allowClear={1 > 0}
            filterOption={handleFilterSearchLabel}
          />
        </FormRow>

        <FormRow style={{ alignItems: "flex-end" }}>
          <InputTextStyled
            label={t("create-claim-form-claim-place")}
            placeholder={"..."}
            readOnly={true}
            value={""}
            disabled={false}
          />
        </FormRow>

        <FormRow style={{ alignItems: "flex-end" }}>
          <DatePickerStyled
            value={""}
            onChange={() => {}}
            placeholder={"gg/mm/aaaa"}
            label={t("create-claim-form-claim-date")}
          />
        </FormRow>

        <div className="buttons-wrapper">
          <ButtonConfirm onClick={() => {}}>
            {t("create-claim-form-claim-button")!}
          </ButtonConfirm>
          <RowSpacer />
          <ButtonConfirm onClick={() => {}}>
            {t("create-claim-form-create-and-confirm-button")!}
          </ButtonConfirm>
        </div>
      </div>
    </CreateClaimWrapper>
  );
};
export default CreateClaim;
