import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  InputTextStyled,
  SelectStyled,
  SwitchStyled,
} from "../../../../../../style/Input";
import TextArea from "antd/lib/input/TextArea";
import AttachmentsPractice from "../../../../../Inputs/AttachmentsPractice";
import {
  AppraisalQuestionnaire,
  AttachmentFile,
  DocumentFiles,
  PracticeAttachment,
} from "../../../../../../redux/apiSpecifications/apiCrud";
import { ButtonConfirm } from "../../../../../Layout/Buttons";
import { Popconfirm } from "antd";
import TooltipStyled from "../../../../../../style/TooltipStyled";

// Styled components
const QuestionnaireWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-select {
    width: 10em;
  }

  .negative-reason {
    margin-left: 5em;
  }

  label {
    width: 15em;
  }

  .ant-form-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .ant-select {
      width: 12em;
    }
  }
`;

const ProofOfAccessWrapper = styled.div`
  display: flex;
  flex-rirection: row;
  width: 100%;
`;

const Question2AttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2em 0 2em 15em;
  gap: 1em;
  width: 100%;

  .document-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .document-name {
      text-transform: uppercase;
      font-size: 0.8em;
    }
  }
`;

const NoteWrapper = styled.div`
  width: 100%;
`;

const ActionsWrapper = styled.div`
  width: 100%;
  padding-top: 3em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1em;
`;

/**
 * @desccription
 * @interface IQuestionnaireProps
 * @property {AppraisalQuestionnaire} questionnaire - the questionnaire
 * @property {boolean} isPrima - the client is "Prima"
 * @property {boolean} hasAccessProof - if the practice already has an access of proof file
 * @property {boolean} isVerbalAgreement - value for isVerbalAgreement
 * @property {boolean} isForcedAgreement - value for isForcedAgreement
 * @property {function} onAddConservativeAgreement - callback when a conservative agreement is added
 * @property {function} onRemoveConservativeAgreement - callback when a conservative agreement is removed
 * @property {function} onReset - function to reset the procedure
 * @property {function} onConfirmQuestionnaire - function to confirm questionnaire
 * @property {function} onConcludePractice - function to conclude practice
 */

interface IQuestionnaireProps {
  questionnaire: AppraisalQuestionnaire;
  isPrima?: boolean;
  onAddConservativeAgreement: (d: DocumentFiles) => void;
  onRemoveConservativeAgreement: () => void;
  onReset: () => void;
  onConfirmQuestionnaire: (result: AppraisalQuestionnaire) => void;
  onConcludePractice: (result: AppraisalQuestionnaire) => void;
}

/**
 * Questionnare component
 * @component
 * @example
 * <Questionnare />
 */
const Questionnaire: React.FC<IQuestionnaireProps> = ({
  questionnaire,
  isPrima,
  onReset,
  onAddConservativeAgreement,
  onRemoveConservativeAgreement,
  onConfirmQuestionnaire,
  onConcludePractice,
}) => {
  // Common variables
  const { t } = useTranslation();

  // Local state
  const [proofOfAccessDocument, setProofOfAccessDocument] =
    useState<DocumentFiles>();

  const [cardIdDocument, setCardIdDocument] = useState<DocumentFiles>();

  const [conservativeAgreementDocument, setConservativeAgreementDocument] =
    useState<DocumentFiles | undefined>(
      questionnaire.documents?.find((d) => d.documentTypeCode == "ACC")
    );

  const [isDamageCompatible, setIsDamageCompatible] = useState<
    string | undefined
  >(questionnaire.isConsistent);
  const [addProofOfAccess, setAddProofOfAccess] = useState<boolean>(false);
  const [userIsAntieconomic, setUserIsAntieconomic] = useState<number>(0);

  useEffect(() => {
    setUserIsAntieconomic(
      questionnaire.isUneconomic === undefined
        ? 0
        : questionnaire.isUneconomic
        ? 1
        : 2
    );
  }, [questionnaire?.isUneconomic, isPrima]);

  const [notes, setNotes] = useState<string>("");

  const yesNoOptions = useMemo(
    () => [
      {
        value: 0,
        label: "---",
      },
      {
        value: 1,
        label: t("switch-yes"),
      },
      {
        value: 2,
        label: t("switch-no"),
      },
    ],
    []
  );

  const yesNoFileOptions = useMemo(
    () => [
      {
        value: "",
        label: "---",
      },
      {
        value: "S",
        label: t("switch-yes"),
      },
      {
        value: "SN",
        label: t("appraisals-conclude-practice-yes-no-file"),
      },
      {
        value: "N",
        label: t("switch-no"),
      },
    ],
    []
  );

  const yesNoVerify = useMemo(
    () => [
      {
        value: "",
        label: "---",
      },
      {
        value: "S",
        label: t("switch-yes"),
      },

      {
        value: "N",
        label: t("switch-no"),
      },
      {
        value: "V",
        label: t("appraisals-conclude-practice-to-verify"),
      },
    ],
    []
  );

  const showIsNegativeQuestion = true;

  const showAgreementQuestion = !questionnaire.isNegative;

  const showIsDamageCompatibleQuestion = questionnaire.isNegative
    ? false
    : questionnaire.agreement !== "";

  const showIsAntieconomic = questionnaire.isNegative
    ? true
    : (isDamageCompatible?.length || 0) > 0;

  const showNote = (() => {
    if (questionnaire.isNegative && questionnaire.negativeReasonCode !== "")
      return true; // negative with reason
    if (
      !questionnaire.isNegative &&
      questionnaire.agreement !== "" &&
      (isDamageCompatible?.length || 0) > 0 &&
      userIsAntieconomic > 0
    )
      return true;

    return false;
  })();

  const showConfirmQuestionnaire =
    showNote ||
    (questionnaire.agreement !== "" &&
      (isDamageCompatible?.length || 0) > 0 &&
      questionnaire.isUneconomic);

  const [popconfirmNegative, setPopconfirmNegative] = useState<boolean>(false);

  const [popconfirmAgreement, setPopconfirmAgreement] =
    useState<boolean>(false);
  const [popconfirmAntieconimic, setPopconfirmAntieconimic] =
    useState<boolean>(false);

  const [popconfirmQuestionnaire, setPopconfirmQuestionnaire] =
    useState<boolean>(false);

  const [popconfirmConcludePractice, setPopconfirmConcludePractice] =
    useState<boolean>(false);

  const [popconfirmProofOfAccess, setPopconfirmAddProofOfAccess] =
    useState<boolean>(false);

  useEffect(() => {
    setConservativeAgreementDocument(
      questionnaire.documents?.find((d) => d.documentTypeCode == "ACC")
    );
  }, [questionnaire]);

  const handleAddAttachmentProofOfAccess = (attachment: PracticeAttachment) => {
    let updatedDocument: DocumentFiles = proofOfAccessDocument
      ? { ...proofOfAccessDocument }
      : {
          documentCode: "",
          documentTypeCode: "PAC",
          attachmentsFiles: [],
        };

    updatedDocument.attachmentsFiles = [
      ...(updatedDocument.attachmentsFiles || []),
      attachment,
    ];

    setProofOfAccessDocument(updatedDocument);
  };

  const handleRemoveAttachmentProofOfAccess = (index: number) => {
    const updatedDocument: DocumentFiles = {
      ...proofOfAccessDocument,
      attachmentsFiles:
        proofOfAccessDocument?.attachmentsFiles?.filter(
          (a, i) => i !== index
        ) || [],
    };
    setProofOfAccessDocument(updatedDocument);

    if ((updatedDocument.attachmentsFiles?.length || 0) === 0) {
      setAddProofOfAccess(false);
      setProofOfAccessDocument(undefined);
    }
  };

  const handleAddAttachmentIdCard = (attachment: PracticeAttachment) => {
    let updatedDocument: DocumentFiles = cardIdDocument
      ? { ...cardIdDocument }
      : {
          documentCode: "",
          documentTypeCode: "ID",
          attachmentsFiles: [],
        };

    updatedDocument.attachmentsFiles = [
      ...(updatedDocument.attachmentsFiles || []),
      attachment,
    ];

    setCardIdDocument(updatedDocument);
  };

  const handleRemoveAttachmentIdCard = (index: number) => {
    const updatedDocument: DocumentFiles = {
      ...cardIdDocument,
      attachmentsFiles: cardIdDocument?.attachmentsFiles?.filter(
        (a, i) => i !== index
      ),
    };

    setCardIdDocument(updatedDocument);
  };

  const handleAddAttachmentConservativeAgreement = (
    attachment: PracticeAttachment
  ) => {
    let updatedDocument: DocumentFiles = conservativeAgreementDocument
      ? { ...conservativeAgreementDocument }
      : {
          documentCode: "",
          documentTypeCode: "ACC",
          attachmentsFiles: [],
        };

    updatedDocument.attachmentsFiles = [
      ...(updatedDocument.attachmentsFiles || []),
      attachment,
    ];

    setConservativeAgreementDocument(updatedDocument);

    onAddConservativeAgreement(updatedDocument);
  };

  const handleRemoveAttachmentConservativeAgreement = (index: number) => {
    const updatedDocument: DocumentFiles = {
      ...conservativeAgreementDocument,
      attachmentsFiles:
        conservativeAgreementDocument?.attachmentsFiles?.filter(
          (a, i) => i !== index
        ) || [],
    };

    setConservativeAgreementDocument(updatedDocument);
    onRemoveConservativeAgreement();
  };

  const handleStartAddAttachmentProofOfAccess = () => {
    setAddProofOfAccess(true);
    setPopconfirmAddProofOfAccess(false);
  };

  const handleConcludePractice = () => {
    const documents: DocumentFiles[] = [];

    if (proofOfAccessDocument) {
      documents.push(proofOfAccessDocument);
    } else if (questionnaire.agreement === "S") {
      // Carica accordo conservativo
      if (cardIdDocument) documents.push(cardIdDocument);

      if (conservativeAgreementDocument)
        documents.push(conservativeAgreementDocument);
    }

    const result: AppraisalQuestionnaire = {
      isNegative: questionnaire.isNegative,
      negativeReasonCode: questionnaire.negativeReasonCode, // to code...
      accessProof: addProofOfAccess === true ? true : questionnaire.accessProof,
      // accessProof: (proofOfAccessDocument?.attachmentsFiles?.length || 0) > 0, // questionnaire.accessProof, // update when added file
      agreement: questionnaire.agreement,
      isVerbalAgreement: questionnaire.isVerbalAgreement, // to add
      isForcedAgreement: questionnaire.isForcedAgreement, // to add
      isConsistent: isDamageCompatible,
      isUneconomic: userIsAntieconomic === 1,
      notes: notes,
      documents: documents, // proofOfAccessDocument ? [proofOfAccessDocument] : [],
    };

    onConcludePractice(result);
  };

  const handleConfirmQuestionnaire = () => {
    const documents: DocumentFiles[] = [];

    const result: AppraisalQuestionnaire = {
      isNegative: questionnaire.isNegative,
      negativeReasonCode: questionnaire.negativeReasonCode, // to code...
      accessProof: questionnaire.accessProof, // update when added file
      agreement: questionnaire.agreement,
      isVerbalAgreement: questionnaire.isVerbalAgreement, // to add
      isForcedAgreement: questionnaire.isForcedAgreement, // to add
      isConsistent: isDamageCompatible,
      isUneconomic: userIsAntieconomic === 1,
      notes: notes,
      documents,
    };

    onConfirmQuestionnaire(result);
  };

  const renderProofOfAccess = () => {
    const switchYes = (
      <SwitchStyled
        label={t("appraisals-conclude-practice-has-access-proof-label")}
        checkedChildren={yesNoOptions[1].label}
        unCheckedChildren={yesNoOptions[2].label}
        disabled={true}
        checked={questionnaire.accessProof}
      />
    );

    const switchNo = (
      <Popconfirm
        placement="top"
        icon={null}
        open={popconfirmProofOfAccess}
        title={t("appraisals-conclude-practice-add-access-proof")}
        onConfirm={() => handleStartAddAttachmentProofOfAccess()}
        onCancel={() => setPopconfirmAddProofOfAccess(false)}
        okText={yesNoOptions[1].label}
        cancelText={yesNoOptions[2].label}
      >
        <SwitchStyled
          label={t("appraisals-conclude-practice-has-access-proof-label")}
          checkedChildren={yesNoOptions[1].label}
          unCheckedChildren={yesNoOptions[2].label}
          onChange={(val) => val && setPopconfirmAddProofOfAccess(true)}
          checked={(proofOfAccessDocument?.attachmentsFiles?.length || 0) > 0}
        />
      </Popconfirm>
    );

    const switchNoButLoaded = (
      <SwitchStyled
        label={t("appraisals-conclude-practice-has-access-proof-label")}
        checkedChildren={yesNoOptions[1].label}
        unCheckedChildren={yesNoOptions[2].label}
        onChange={(val) => setPopconfirmAddProofOfAccess(true)}
        checked={(proofOfAccessDocument?.attachmentsFiles?.length || 0) > 0}
      />
    );

    return (
      <div className="row">
        <ProofOfAccessWrapper>
          <TooltipStyled
            content={t(
              "appraisals-conclude-practice-questionnaire-proof-access-tooltip"
            )}
          />

          {questionnaire.accessProof && switchYes}

          {!questionnaire.accessProof && (
            <>
              {!addProofOfAccess && switchNo}
              {addProofOfAccess && switchNoButLoaded}

              {addProofOfAccess && (
                <AttachmentsPractice
                  onAddAttachment={handleAddAttachmentProofOfAccess}
                  onRemoveAttachment={handleRemoveAttachmentProofOfAccess}
                  attachments={proofOfAccessDocument?.attachmentsFiles || []}
                  acceptedFormats={"application/pdf, image/*"}
                  maxFiles={10}
                  showFilename={true}
                  typeName={t("appraisal-details-tab-documents-filetype-PAC")}
                  autopen={true}
                />
              )}
            </>
          )}
        </ProofOfAccessWrapper>
      </div>
    );
  };

  const renderIsAntiEconomic = () => {
    const ifIsPrima = (
      <Popconfirm
        placement="top"
        icon={null}
        open={popconfirmAntieconimic}
        title={t("appraisals-conclude-practice-change-antieconomic")}
        onConfirm={onReset}
        onCancel={() => setPopconfirmAntieconimic(false)}
        okText={t("appraisals-conclude-practice-change-antieconomic-new-adz")}
        cancelText={t(
          "appraisals-conclude-practice-change-antieconomic-cancel"
        )}
      >
        <SelectStyled
          label={t(
            "appraisals-conclude-practice-questionnaire-question-4-label"
          )}
          options={yesNoOptions}
          onChange={(val) => setPopconfirmAntieconimic(true)}
          value={userIsAntieconomic}
        />
      </Popconfirm>
    );

    const ifIsNotPrima = (
      <SelectStyled
        label={t("appraisals-conclude-practice-questionnaire-question-4-label")}
        options={yesNoOptions}
        onChange={setUserIsAntieconomic}
        value={userIsAntieconomic}
      />
    );

    return (
      <>
        <TooltipStyled
          content={t(
            "appraisals-conclude-practice-questionnaire-question-4-tooltip"
          )}
        />
        {isPrima ? ifIsPrima : ifIsNotPrima}
      </>
    );
  };

  const showAgreemetAttachments =
    (cardIdDocument?.attachmentsFiles?.length || 0) +
      (conservativeAgreementDocument?.attachmentsFiles?.length || 0) >
    0;

  return (
    <QuestionnaireWrapper>
      <QuestionsWrapper>
        {showIsNegativeQuestion && (
          <QuestionWrapper>
            <div className="row">
              <TooltipStyled
                content={t(
                  "appraisals-conclude-practice-questionnaire-question-1-tooltip"
                )}
              />
              <Popconfirm
                placement="top"
                icon={null}
                open={popconfirmNegative}
                title={
                  <div>
                    {t("appraisals-conclude-practice-change-negative-1")}
                    <br />
                    {t("appraisals-conclude-practice-change-negative-2")}
                  </div>
                }
                onConfirm={onReset}
                onCancel={() => setPopconfirmNegative(false)}
                okText={yesNoOptions[1].label}
                cancelText={yesNoOptions[2].label}
              >
                <SwitchStyled
                  label={t(
                    "appraisals-conclude-practice-questionnaire-question-1-label"
                  )}
                  checkedChildren={yesNoOptions[1].label}
                  unCheckedChildren={yesNoOptions[2].label}
                  onChange={(val) => setPopconfirmNegative(true)}
                  checked={questionnaire.isNegative}
                />
              </Popconfirm>
              {questionnaire.negativeReasonCode !== "" && (
                <div className="negative-reason">
                  {t(
                    `appraisals-conclude-practice-questionnaire-is-negative-reason-${questionnaire.negativeReasonCode}`
                  )}
                </div>
              )}
            </div>
          </QuestionWrapper>
        )}

        {showIsNegativeQuestion && questionnaire.isNegative === true && (
          <QuestionWrapper>{renderProofOfAccess()}</QuestionWrapper>
        )}

        {showAgreementQuestion && (
          <QuestionWrapper>
            <div className="row">
              <TooltipStyled
                content={t(
                  "appraisals-conclude-practice-questionnaire-question-2-tooltip"
                )}
              />
              <Popconfirm
                placement="top"
                icon={null}
                open={popconfirmAgreement}
                title={
                  <div>
                    {t("appraisals-conclude-practice-change-agreement-1")}
                    <br />
                    {t("appraisals-conclude-practice-change-negative-2")}
                  </div>
                }
                onConfirm={onReset}
                onCancel={() => setPopconfirmAgreement(false)}
                okText={yesNoOptions[1].label}
                cancelText={yesNoOptions[2].label}
              >
                <SelectStyled
                  label={t(
                    "appraisals-conclude-practice-questionnaire-question-2-label"
                  )}
                  options={yesNoFileOptions}
                  onChange={(val) => setPopconfirmAgreement(true)}
                  value={questionnaire.agreement}
                />
              </Popconfirm>
            </div>

            {showAgreemetAttachments && (
              <Question2AttachmentsWrapper>
                {(questionnaire.agreement === "S" ||
                  questionnaire.agreement === "SN") && (
                  <>
                    <div className="document-wrapper">
                      <AttachmentsPractice
                        onAddAttachment={handleAddAttachmentIdCard}
                        onRemoveAttachment={handleRemoveAttachmentIdCard}
                        attachments={cardIdDocument?.attachmentsFiles || []}
                        acceptedFormats={"image/*"}
                        maxFiles={2}
                        showFilename={true}
                      />
                      {!(
                        (cardIdDocument?.attachmentsFiles?.length || 0) > 0
                      ) && (
                        <div className="document-name">
                          {t("appraisal-details-tab-documents-filetype-ID")}
                        </div>
                      )}
                    </div>

                    <div className="document-wrapper">
                      <AttachmentsPractice
                        onAddAttachment={
                          handleAddAttachmentConservativeAgreement
                        }
                        onRemoveAttachment={
                          handleRemoveAttachmentConservativeAgreement
                        }
                        attachments={
                          conservativeAgreementDocument?.attachmentsFiles || []
                        }
                        acceptedFormats={"application/pdf"}
                        maxFiles={1}
                        showFilename={true}
                      />
                      {!(
                        (conservativeAgreementDocument?.attachmentsFiles
                          ?.length || 0) > 0
                      ) && (
                        <div className="document-name">
                          {t("appraisal-details-tab-documents-filetype-ACC")}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Question2AttachmentsWrapper>
            )}
          </QuestionWrapper>
        )}

        {showIsDamageCompatibleQuestion && (
          <QuestionWrapper>
            <div className="row">
              <TooltipStyled
                content={t(
                  "appraisals-conclude-practice-questionnaire-question-3-tooltip"
                )}
              />
              <SelectStyled
                label={t(
                  "appraisals-conclude-practice-questionnaire-question-3-label"
                )}
                options={yesNoVerify}
                onChange={setIsDamageCompatible}
                value={isDamageCompatible}
              />
            </div>
          </QuestionWrapper>
        )}

        {showIsAntieconomic && (
          <QuestionWrapper>
            <div className="row">{renderIsAntiEconomic()} </div>
          </QuestionWrapper>
        )}

        {showNote && (
          <QuestionWrapper>
            <NoteWrapper>
              <div>{t("appraisals-conclude-practice-note-label")}</div>
              <TextArea
                placeholder={
                  t("appraisals-conclude-practice-note-placeholder")!
                }
                onChange={(e) => setNotes(e.target.value)}
              />
            </NoteWrapper>{" "}
          </QuestionWrapper>
        )}
      </QuestionsWrapper>

      {showConfirmQuestionnaire && (
        <ActionsWrapper>
          {(questionnaire.agreement === "S" ||
            questionnaire.agreement === "N" ||
            questionnaire.isNegative ||
            (questionnaire.agreement === "SN" &&
              questionnaire.isVerbalAgreement === true)) && (
            <Popconfirm
              placement="top"
              icon={null}
              open={popconfirmConcludePractice}
              title={t(
                "appraisals-conclude-practice-save-conclude-practice-confirm"
              )}
              onConfirm={handleConcludePractice}
              onCancel={() => setPopconfirmConcludePractice(false)}
              okText={yesNoOptions[1].label}
              cancelText={yesNoOptions[2].label}
            >
              <ButtonConfirm
                onClick={() => setPopconfirmConcludePractice(true)}
              >
                {t("appraisals-conclude-practice-button-conclude-practice")!}
              </ButtonConfirm>
            </Popconfirm>
          )}

          {questionnaire.agreement === "SN" &&
            (questionnaire.isVerbalAgreement ?? false) === false && (
              <Popconfirm
                placement="top"
                icon={null}
                open={popconfirmQuestionnaire}
                title={t(
                  "appraisals-conclude-practice-save-questionnaire-confirm"
                )}
                onConfirm={handleConfirmQuestionnaire}
                onCancel={() => setPopconfirmQuestionnaire(false)}
                okText={yesNoOptions[1].label}
                cancelText={yesNoOptions[2].label}
              >
                <ButtonConfirm onClick={() => setPopconfirmQuestionnaire(true)}>
                  {t("appraisals-conclude-practice-button-save-questionnaire")!}
                </ButtonConfirm>
              </Popconfirm>
            )}
        </ActionsWrapper>
      )}
    </QuestionnaireWrapper>
  );
};

export default Questionnaire;
