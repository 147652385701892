import {
    combineReducers,
    configureStore,
    PreloadedState,
} from "@reduxjs/toolkit";
import userReducer from "../redux/features/userSlice";
import activeClaimReducer from "./features/activeClaimSlice";
import policiesReducer from "./features/policySlice";
import activeSubjectReducer from "./features/activeSubject";
import mainTabsSliceReducer from "./features/mainTabsSlice";
import appraisersReducer from "./features/appraisersSlice";
//import agendaAppraiserReducer from "./features/agendaAppraiserSlice";

import { localstorageMiddleware } from "./localstorageMiddleware";
import { dataValidationMiddleware } from "./dataValidationMiddleware";
import { appraisersMiddleware } from "./appraisersMiddleware";

import { apiCrud } from "./apiSpecifications/apiCrud";
import { apiFesf } from "./apiSpecifications/apiFesf";
import { apiDocs } from "./apiSpecifications/apiDocs";
import { apiSubjects } from "./apiSpecifications/apiSubjects";
import { apiClaims } from "./apiSpecifications/apiClaims";

import { mainTabsMiddleware } from "./mainTabsMiddleware";
import { NotificationsReducer } from "./features/notificationsSlice";

const rootReducer = combineReducers({
    // agendaAppraiser: agendaAppraiserReducer,
    appraisers: appraisersReducer,
    user: userReducer,
    mainTabs: mainTabsSliceReducer,
    activeSubject: activeSubjectReducer,
    activeClaim: activeClaimReducer,
    policies: policiesReducer,
    notifications: NotificationsReducer,
    [apiCrud.reducerPath]: apiCrud.reducer,
    [apiFesf.reducerPath]: apiFesf.reducer,
    [apiDocs.reducerPath]: apiDocs.reducer,
  [apiSubjects.reducerPath]: apiSubjects.reducer,
    [apiClaims.reducerPath]: apiClaims.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(apiCrud.middleware)
            .concat(apiFesf.middleware)
            .concat(apiDocs.middleware)
      .concat(apiSubjects.middleware)
            .concat(apiClaims.middleware)
            .concat(dataValidationMiddleware)
            .concat(localstorageMiddleware)
            .concat(mainTabsMiddleware)
            .concat(appraisersMiddleware),
});

// setup test store (without localStorage middleware)
export const setupTestStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false })
                .concat(apiCrud.middleware)
                .concat(apiFesf.middleware)
                .concat(apiDocs.middleware)
        .concat(apiSubjects.middleware)
                .concat(apiClaims.middleware)
                .concat(dataValidationMiddleware)
                .concat(mainTabsMiddleware),
    });
};

export type AppStore = ReturnType<typeof setupTestStore>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
