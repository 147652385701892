import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { setInfoAlwaysVisibleTop } from "../../../../../redux/features/userSlice";
import {
  DashboardA1Summary,
  SearchedClaimsList,
  useLazyGetClaimsSummaryA1Query,
  useSearchClaimsMutation,
} from "../../../../../redux/apiSpecifications/apiClaims";
import ClaimsDonutsHighlighted, {
  ClaimsDonutKey,
} from "./ClaimsDonutsHighlighted/ClaimsDonutsHighlighted";
import CreateClaim from "./CreateClaim/CreateClaim";
import CreateSubject from "./CreateSubject/CreateSubject";
import ClaimsTable from "./ClaimsTable";
import {
  DashboardWrapper,
  LoadingInTabWrapper,
  RefreshInTabWrapper,
} from "../../../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../../../config/icons";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

const DashboardA1Wrapper = styled(DashboardWrapper)`
  display: grid;
  grid-template-areas:
    "claimsInEvidence createClaim createSubject"
    "claimsList claimsList claimsList"
    "spacer spacer spacer";
  grid-template-columns: 3fr 2fr 2fr;
  grid-template-rows: min-content auto auto;
  column-gap: 1em;
  row-gap: 1em;
`;

type SearchClaimsParams = {
  pageNum: number;
  pageLen: number;
  companyList: number[];
  claimStatus?: string;
  isReopened?: boolean;
};

type LoadingStatus = "idle" | "loading" | "refreshing" | undefined;

const PAGE_LEN: number = 5;

interface IDashboardA1Props {
  tabKey?: number;
}

const DashBoard_A1: React.FC<IDashboardA1Props> = ({ tabKey }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { selectedTenants, activeRole } = useSelector(
    (state: RootState) => state.user
  );

  const { activeKey } = useSelector((state: RootState) => state.mainTabs);

  const [loadingSummaryStatus, setLoadingSummaryStatus] =
    useState<LoadingStatus>(undefined);

  const [loadingClaimsListStatus, setLoadingClaimsListStatus] =
    useState<LoadingStatus>(undefined);

  const [summary, setSummary] = useState<DashboardA1Summary | undefined>(
    undefined
  );

  const [claimsList, setClaimsList] = useState<
    SearchedClaimsList | undefined
  >();

  const [searchClaimsParams, setSearchClaimsParams] = useState<
    SearchClaimsParams | undefined
  >(undefined);
  // {
  //     pageLen: PAGE_LEN,
  //     pageNum: 1,
  //     companyList: selectedTenants?.map((tenant) => tenant.id!),
  // } as SearchClaimsParams);

  const [claimsSummaryData] = useLazyGetClaimsSummaryA1Query();
  const [searchClaims] = useSearchClaimsMutation();

  const loadingStatus: LoadingStatus = useMemo(() => {
    if (
      loadingSummaryStatus === "loading" ||
      loadingClaimsListStatus === "loading"
    )
      return "loading";

    if (
      loadingSummaryStatus === "refreshing" ||
      loadingClaimsListStatus === "refreshing"
    )
      return "refreshing";

    if (loadingSummaryStatus === "idle" || loadingClaimsListStatus === "idle")
      return "idle";

    return undefined;
  }, [loadingSummaryStatus, loadingClaimsListStatus]);

  // Primo caricamento o riattivazione del tab
  useEffect(() => {
    if (activeKey === tabKey) {
      if (
        loadingSummaryStatus === undefined ||
        loadingSummaryStatus === "idle"
      ) {
        setLoadingSummaryStatus(
          loadingSummaryStatus ? "refreshing" : "loading"
        );
      }

      if (searchClaimsParams === undefined) {
        setSearchClaimsParams({
          pageLen: PAGE_LEN,
          pageNum: 1,
          companyList: selectedTenants?.map((tenant) => tenant.id!),
        } as SearchClaimsParams);
      } else {
        if (
          loadingClaimsListStatus === undefined ||
          loadingClaimsListStatus === "idle"
        ) {
          setLoadingClaimsListStatus(
            loadingClaimsListStatus === undefined ? "loading" : "refreshing"
          );
        }
      }
    }
  }, [activeKey]);

  // Caricamento Summary
  useEffect(() => {
    if (
      loadingSummaryStatus &&
      ["loading", "refreshing"].includes(loadingSummaryStatus)
    ) {
      (async () => {
        await loadSummary();

        setLoadingSummaryStatus("idle");
      })();
    }
  }, [loadingSummaryStatus]);

  // Caricamento Claims
  useEffect(() => {
    if (
      loadingClaimsListStatus &&
      ["loading", "refreshing"].includes(loadingClaimsListStatus)
    ) {
      (async () => {
        await loadClaims();

        setLoadingClaimsListStatus("idle");
      })();
    }
  }, [loadingClaimsListStatus]);

  // Aggiornamento filtro di ricerca Claims
  useEffect(() => {
    // console.info(searchClaimsParams);
    if (searchClaimsParams !== undefined) {
      if (
        loadingClaimsListStatus === undefined ||
        loadingClaimsListStatus === "idle"
      ) {
        setLoadingClaimsListStatus(
          loadingClaimsListStatus === undefined ? "loading" : "refreshing"
        );
      }
    }
  }, [searchClaimsParams]);

  // useEffect(() => {

  // }, [selectedTenants]);

  const loadSummary = async () => {
    try {
      const tenantsIds = selectedTenants?.map((tenant) => tenant.id!);
      const response = await claimsSummaryData({
        tenants: tenantsIds!,
      });

      if (response?.data) {
        setSummary(response.data);
      }
    } catch (err) {
      dispatch(
        setInfoAlwaysVisibleTop({
          type: "error",
          message: t("http-error-loading-dashboard"),
        })
      );
    }
  };

  const loadClaims = async () => {
    // const { pageNum, pageLen, companyList, claimStatus, isReopened } =
    //     searchClaimsParams;

    const response = await searchClaims({
      pageNum: searchClaimsParams?.pageNum!,
      pageLen: searchClaimsParams?.pageLen!,
      scenario: "dashboard",
      activeRole: activeRole!,
      searchClaimsParams: {
        companyList: searchClaimsParams?.companyList,
        claimStatus: searchClaimsParams?.claimStatus,
        isReopened: searchClaimsParams?.isReopened,
      },
    });

    const correctResponse = response as {
      data: SearchedClaimsList;
    };

    if (correctResponse && correctResponse.data) {
      setClaimsList(correctResponse.data);
    } else {
      const errorResponse = response as {
        error: FetchBaseQueryError;
      };
    }
  };

  const handleDonutItemClick = (donutKey: ClaimsDonutKey, itemKey: string) => {
    setSearchClaimsParams({
      ...searchClaimsParams!,
      pageNum: 1,
      pageLen: PAGE_LEN,
      claimStatus: itemKey,
      isReopened:
        donutKey === "inDraft" ? undefined : donutKey === "inProgressReopened",
    });
  };

  const handleResetFilter = () => {
    setSearchClaimsParams({
      ...searchClaimsParams!,
      pageNum: 1,
      pageLen: PAGE_LEN,
      claimStatus: undefined,
      isReopened: undefined,
    });
  };

  const handlePaginationChange = (pageIndex: number, pageSize: number) => {
    setSearchClaimsParams({
      ...searchClaimsParams!,
      pageNum: pageIndex,
      pageLen: pageSize,
    });
  };

  const selectedDonutItem: string | undefined = useMemo(() => {
    if (searchClaimsParams?.claimStatus !== undefined) {
      if (searchClaimsParams.isReopened === undefined)
        return `inDraft-${searchClaimsParams.claimStatus}`;

      if (searchClaimsParams.isReopened) {
        return `inProgressReopened-${searchClaimsParams.claimStatus}`;
      }

      return `inProgress-${searchClaimsParams.claimStatus}`;
    }

    return undefined;
  }, [searchClaimsParams?.claimStatus, searchClaimsParams?.isReopened]);

  return (
    <div>
      {/* <pre>{JSON.stringify(searchClaimsParams, null, 2)}</pre> */}

      {loadingStatus === "loading" && (
        <LoadingInTabWrapper>
          <div className="box">{IconLoading}</div>
        </LoadingInTabWrapper>
      )}

      {(loadingStatus === "refreshing" || loadingStatus === "idle") && (
        <>
          <DashboardA1Wrapper>
            <ClaimsDonutsHighlighted
              summary={summary}
              selectedDonutItem={selectedDonutItem}
              gridArea="claimsInEvidence"
              onDonutItemClick={handleDonutItemClick}
              onResetFilter={handleResetFilter}
            />

            <CreateClaim gridArea="createClaim" />

            <CreateSubject gridArea="createSubject" />

            {claimsList && (
              <ClaimsTable
                data={claimsList}
                onPaginationChange={handlePaginationChange}
                gridArea="claimsList"
              />
            )}
          </DashboardA1Wrapper>

          {loadingStatus === "refreshing" && (
            <RefreshInTabWrapper>
              <div className="box">{IconLoading}</div>
            </RefreshInTabWrapper>
          )}
        </>
      )}
    </div>
  );
};

export default DashBoard_A1;
