import styled from "styled-components";
import { SupplierNetworkCollaborator } from "../../../../../redux/apiSpecifications/apiFesf";
import { FormRow } from "../../../../../style/form";
import { InputTextStyled } from "../../../../../style/Input";
import { useTranslation } from "react-i18next";
import { RowSpacer } from "../../../../../style/containers";
import { IconDelete } from "../../../../../config/icons";

const AppraiserDetailsSubnetCollaboratorFormWrapper = styled.div`
    background-color: #f8f8f8;
    padding: 0.5em 1em 1em 1em;
    border-radius: 5px;

    .input-denomination {
        width: 25em;
    }

    .input-dekra-account-number {
        width: 10em;
    }

    .input-membership-number {
        width: 10em;
    }

    .delete-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;

        .delete-icon-button {
            font-size: 1.6em;
            color: red;
            svg {
                cursor: pointer;
            }
            margin: 0 0.5em -5px 0;
        }
    }
`;

interface IAppraiserDetailsSubnetCollaboratorFormProps {
    collaborator: SupplierNetworkCollaborator;
    onChange: (value: any, field: string) => void;
    onDelete: () => void;
}

const AppraiserDetailsSubnetCollaboratorForm = ({
    collaborator,
    onChange,
    onDelete,
}: IAppraiserDetailsSubnetCollaboratorFormProps) => {
    const { t } = useTranslation();

    return (
        <AppraiserDetailsSubnetCollaboratorFormWrapper>
            <FormRow style={{ alignItems: "flex-end" }}>
                <InputTextStyled
                    classNameInput="input-denomination"
                    label={t(
                        "edit-appraiser-subnet-collaborator-denomination-label"
                    )}
                    value={collaborator?.denomination}
                    onChange={(val) => onChange(val, "denomination")}
                    disabled={!!collaborator?.dekraAccountNumber}
                />

                <RowSpacer />

                <InputTextStyled
                    classNameInput="input-dekra-account-number"
                    label={t(
                        "edit-appraiser-subnet-collaborator-dekra-account-number-label"
                    )}
                    tooltip={t(
                        "edit-appraiser-subnet-collaborator-dekra-account-number-tooltip"
                    )}
                    value={collaborator?.dekraAccountNumber}
                    onChange={() => {}}
                    disabled={true}
                />

                <RowSpacer />

                <InputTextStyled
                    classNameInput="input-membership-number"
                    label={t(
                        "edit-appraiser-subnet-collaborator-membership-number-label"
                    )}
                    tooltip={t(
                        "edit-appraiser-subnet-collaborator-membership-number-tooltip"
                    )}
                    value={collaborator?.membershipNumber}
                    onChange={(val) => onChange(val, "membershipNumber")}
                />

                <RowSpacer />

                <div className="delete-wrapper">
                    {!collaborator?.dekraAccountNumber && (
                        <div className="delete-icon-button" onClick={onDelete}>
                            <IconDelete />
                        </div>
                    )}
                </div>
            </FormRow>
        </AppraiserDetailsSubnetCollaboratorFormWrapper>
    );
};

export default AppraiserDetailsSubnetCollaboratorForm;
